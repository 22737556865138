import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EggLoader from './egg.gif'
import './loader.css'
const LoaderPage = (props) => {
    return (
        <>
            <Container>
                <br/><br/>
                <Row className="justify-content-md-center">
                    <Col xs={12} sm={4} md={4}>
                        <img src={EggLoader} style={{ maxWidth: '23rem', display: 'flex' }} alt=""/>
                        
                    </Col>
                </Row>
                <center><h3>Loading please wait....</h3></center>
            </Container>
        </>
    )
}

export default LoaderPage;