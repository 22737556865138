import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    boxes: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0),
    trays: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0),
    loose_eggs: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0),
    total_eggs: Yup.number().transform(value =>
        isNaN(value) ? undefined : value
      ).required('Total Eggs Are Required'),
    sale_egg_rate: Yup.number().required('sale egg rate is Required'),
    log_date: Yup.string()
    .required('Log Date is required')
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Log date must be a valid date in the format YYYY-MM-DD'),
    
  });