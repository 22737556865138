import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import Datatable from "react-data-table-component-with-filter";
import { AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../components/DataTable/customTable.css';
import AddOrg from '../components/Forms/AddOrg';
import moment from 'moment';
import CustomModal from '../components/Modal/CustomModal';
import { connectDelete, connectGet } from '../helpers/Connect';
import AuthContext from '../store/auth-context';
import Role from '../helpers/Role';
import LoaderPage from '../components/Loader/LoaderPage';

function Organization() {
  const [addOrg, setAddOrg] = useState(false)
  const [showOrg, setShowOrg] = useState(true)
  const [data, setData] = useState([])
  const [pending, setPending] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [orgId, setOrgId] = useState('')
  const [editData, setEditData] = useState({})
  const [loading, setLoading] = useState(false)
  const authCtx = useContext(AuthContext)
  const { REACT_APP_HAWKEYE_URL } = process.env
  const navigate = useNavigate()

  const fetchOrg = () => {
    let finalUrl
    if(authCtx.role === 'admin'){
      finalUrl = REACT_APP_HAWKEYE_URL + "/organization/" + authCtx.orgId
    }else if(authCtx.role === 'super-admin'){
      finalUrl = REACT_APP_HAWKEYE_URL + "/organization"
    }
    connectGet(finalUrl, authCtx.token)
      .then(response => {
        if(Array.isArray(response.payload)){
          setData(response.payload)
        }else{
          setData([response.payload])
        }
        setPending(false)
        setLoading(false)
      })
      .catch(error => console.log('test', error));
  }

  useEffect(() => {
    setLoading(true)
    fetchOrg()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOrgId = (row) => {
    setShowModal(true)
    setOrgId(row)
  }

  const deleteOrg = () => {
    let finalUrl = REACT_APP_HAWKEYE_URL + '/organization/' + orgId
    connectDelete(finalUrl, authCtx.token)
      .then(response => {
        fetchOrg()
        setShowModal(false)
      })
  }

  const newOrgHandler = () => {
    setEditData({})
    setAddOrg(true);
    setShowOrg(false);
  }

  const cancelHandler = () => {
    setAddOrg(false);
    setShowOrg(true);
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  const editClickHandler = (row) => {
    setEditData(row)
    setShowOrg(false)
    setAddOrg(true)
  }

  const header = [
    { name: 'Organization', selector: row => row['organization_name'], sortable: true },
    { name: 'License Start', selector: row => row.start_date, format: row => moment(row.start_date).format('DD-MM-YYYY')},
    { name: 'License End', selector: row => row.end_date, format: row => moment(row.end_date).format('DD-MM-YYYY')},
    {
      name: 'Actions',
      cell: (row) => <><AiOutlineEye onClick={() => navigate('../viewOrg/'+row.id+'/'+row.organization_name)}/>&nbsp; {authCtx.role ===  Role.SuperAdmin && <FaEdit onClick={() => editClickHandler(row)}/>}&nbsp;{authCtx.role === Role.SuperAdmin && <AiOutlineDelete onClick={() => getOrgId(row.id)} />}</>,
    }
  ];

  const customStyles = {
    headRow: {
      style: {
        textTransform: 'uppercase',
        backgroundColor: '#edeafa',
        fontSize: '17px'
      },
    },
    rows: {
      style: {
        fontSize: '16px'
      }
      
    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  }

  return (
   loading ? <LoaderPage/> :
   <>

      {addOrg &&
        <AddOrg cancelHandler={cancelHandler} fetchOrg={fetchOrg} editData={editData}/>
      }
      {
        showOrg &&
        <>
          <CustomModal show={showModal}
            message="Are you sure you want to delete org?"
            handleModalClose={handleModalClose} handlerFunction={deleteOrg} />
          <Container>
            <br />
            <h3>Organizations</h3>
            <hr />
          </Container>
          <Container>
            <Card>

              <Card.Body>
                <Card.Title>{authCtx.role === Role.SuperAdmin && <Button className="float-end" variant="custom" onClick={newOrgHandler}>Add New Org</Button>}</Card.Title>
                <Datatable
                  columns={header}
                  data={data}
                  pagination
                  progressPending={pending}
                  customStyles={customStyles}
                  highlightOnHover
                  pointerOnHover
                />
              </Card.Body>
            </Card>
          </Container>
        </>
      }
    </>
  );
}

export default Organization;