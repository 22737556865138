import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useState } from "react";
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connectPost } from "../../helpers/Connect";
import { validationSchema } from "../../helpers/form-validation/ShedForm";
import AuthContext from "../../store/auth-context";
import './customError.css';

const AddShed = (props) => {
    
    const preloadedValues = {
        building_name: props.editData.building_name,
        building_type: props.editData.building_type,
        building_capacity: props.editData.building_capacity
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: preloadedValues
    });


    const [show, setShow] = useState(false)
    const authCtx = useContext(AuthContext)

    const onSubmit = formData => {
        let addBatchData;
        let addBatchUrl;
        let methodIdentifier;

        if(Object.keys(props.editData).length === 0){
            addBatchData = JSON.stringify({
                "building_name": formData.building_name,
                "building_type": formData.building_type,
                "building_capacity": formData.building_capacity,
                "farm_id": props.farmId
            })
            addBatchUrl = process.env.REACT_APP_HAWKEYE_URL + "/building"
            methodIdentifier='POST'

        } else{
            addBatchData = JSON.stringify({
                "building_name": formData.building_name,
                "building_type": formData.building_type,
                "building_capacity": formData.building_capacity,
                "farm_id": props.editData.farm_id
            })
            addBatchUrl = process.env.REACT_APP_HAWKEYE_URL + "/building/" + props.editData.id
            methodIdentifier='PATCH'
        }
        
        connectPost(addBatchUrl, addBatchData, authCtx.token, methodIdentifier)
            .then(response => {
                props.cancelHandler(props.identifier)
            })
            .catch(error => {
                setShow(true)
            })
    }
    
    return (
        <>

            <Container>
                <br />
                <h3>{Object.keys(props.editData).length === 0 ? 'Add':'Edit'} Building</h3>
                <hr />
            </Container>
            <Container>
                <Card >
                    <Card.Body>
                        <Alert variant="danger" show={show}>Internal Error. Please try again later</Alert>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Building Name</Form.Label>
                                <Form.Control type="text"
                                    name="building_name"
                                    placeholder="Enter building name"
                                    {...register('building_name')} />
                                <span className="validation-error">{errors.building_name?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Building Type</Form.Label>
                                <Form.Control type="text"
                                    name="building_type"
                                    placeholder="Enter building type"
                                    {...register('building_type')} />
                                <span className="validation-error">{errors.building_type?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicNumber">
                                <Form.Label>Building Capacity</Form.Label>
                                <Form.Control type="number"
                                    name="building_capacity"
                                    placeholder="Enter building capacity"
                                    {...register('building_capacity')} />
                                <span className="validation-error">{errors.building_capacity?.message}</span>
                            </Form.Group>
                            <Button variant="custom" type="submit">
                                Submit
                            </Button> &nbsp;
                            <Button variant="customSecondary" type="submit" onClick={() => props.cancelHandler(props.identifier)}>
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )

}

export default AddShed;