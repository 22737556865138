import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../../store/auth-context';


const PrivateRoute = () => {
    const authCtx = useContext(AuthContext);
    return (
        authCtx.isLoggedIn ? <Outlet /> : <Navigate to="/login" />
        );
};

export default PrivateRoute;