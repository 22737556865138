import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useState } from "react";
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connectPost } from "../../helpers/Connect";
import { validationSchema } from "../../helpers/form-validation/OrgForm";
import AuthContext from "../../store/auth-context";
import './customError.css';

const AddOrg = (props) => {

    const preloadedValues = {
        organization_name: props.editData.organization_name,
        start_date: Object.keys(props.editData).length !== 0 ? props.editData.start_date.split('T')[0]: '',
        end_date: Object.keys(props.editData).length !== 0 ? props.editData.end_date.split('T')[0]: ''

    }
    

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: preloadedValues
    });


    const [show, setShow] = useState(false)
    const authCtx = useContext(AuthContext)

    const onSubmit = formData => {
        
        let addOrgUrl
        let methodIdentifier
        const addOrgData = JSON.stringify({
            ...formData
        })

        if(Object.keys(props.editData).length === 0){
            addOrgUrl = process.env.REACT_APP_HAWKEYE_URL + "/organization"
            methodIdentifier = 'POST'
        }else {
            addOrgUrl = process.env.REACT_APP_HAWKEYE_URL + "/organization/" + props.editData.id
            methodIdentifier = 'PATCH'
        }
        
        connectPost(addOrgUrl, addOrgData, authCtx.token, methodIdentifier)
            .then(response => {
                props.fetchOrg()
                props.cancelHandler()
                
            })
            .catch(error => {
                setShow(true)
            })
    }
    
    return (
        <>

            <Container>
                <br />
                <h3>Add Organization</h3>
                <hr />
            </Container>
            <Container>
                <Card >
                    <Card.Body>
                        <Alert variant="danger" show={show}>Internal Error. Please try again later</Alert>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Organization Name</Form.Label>
                                <Form.Control type="text"
                                    name="organization_name"
                                    placeholder="Enter organization name"
                                    {...register('organization_name')} />
                                <span className="validation-error">{errors.organization_name?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>License Start</Form.Label>
                                <Form.Control type="date"
                                    name="start_date"
                                    {...register('start_date')} />
                                <span className="validation-error">{errors.firstname?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>License End</Form.Label>
                                <Form.Control type="date"
                                    name="end_date"
                                    {...register('end_date')} />
                                <span className="validation-error">{errors.lastname?.message}</span>
                            </Form.Group>
                            <Button variant="custom" type="submit">
                                Submit
                            </Button> &nbsp;
                            <Button variant="customSecondary" type="submit" onClick={props.cancelHandler}>
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )

}

export default AddOrg;