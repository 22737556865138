import { React, useContext, useState } from "react";
import { Button, Form, Image, Alert } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import '../components/Forms/customError.css';
import AuthContext from "../store/auth-context";
import './login.css';
import poultry from './poultry.png';


function Login() {

    const [formData, setFormData] = useState({ phoneNumber: "", password: "" })
    const [error, setError] = useState('')
    const [showReset, setShowReset] = useState(false)
    const [resetResponse, setResetResponse] = useState('')
    const auth_ctx = useContext(AuthContext)
    const history = useNavigate()
    const {REACT_APP_AUTHO_URL} = process.env
    const {REACT_APP_HAWKEYE_URL} = process.env
    const {REACT_APP_AUTHO_AUDIENCE} = process.env
    const {REACT_APP_AUTHO_CLIENT_TOKEN} = process.env

    function onChange(event) {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    function onForgotClickHandler() {
        setShowReset(!showReset)
        setResetResponse('')
    }

    function onSubmit(event){
        event.preventDefault();
        if(showReset){
            
            var resetBody = JSON.stringify({
                "username": formData.phoneNumber
            })
            var resetHeaders = new Headers();
            resetHeaders.append("Content-Type", "application/json");
            var resetRequestOptions = {
                method: 'POST',
                headers: resetHeaders,
                body: resetBody
            }
            fetch(REACT_APP_HAWKEYE_URL+ "/auth/resetPassword", resetRequestOptions)
            .then(response => {return response.text()})
            .then(data => {
                setResetResponse(data)
            })
            .catch(error => console.log(error))
        }else{
            var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "client_id": REACT_APP_AUTHO_CLIENT_TOKEN,
            "username": formData.phoneNumber,
            "password": formData.password,
            "grant_type": "password",
            "audience": REACT_APP_AUTHO_AUDIENCE
          });
          
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
          };
          
          fetch(REACT_APP_AUTHO_URL + "/oauth/token", requestOptions)
            .then(response => {
                
                if (!response.ok) {
                    setError('Invalid username or password')
                    throw Error(response.status);
                }else{
                    return response.json()
                }
                
            })
            .then((data) => {
                auth_ctx.login(data.access_token)
                history('/')
            })
            .catch(error => console.log('error', error));
        }
        
    }
    return (
        <>
            <div className="main-div">
                <div className="color-overlay d-flex justify-content-center align-items-center">
                    <Form className="form-custom rounded p-4 p-sm-6">

                        <Image src={poultry}
                            className='img-fluid shadow-2-strong'
                            alt=''
                            style={{ maxWidth: '18rem' }}
                            rounded
                        />


                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="number" placeholder="Phone Number" name="phoneNumber" value={formData.phoneNumber} onChange={onChange} />
                        </Form.Group>

                        {!showReset && 
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" name="password" value={formData.password} onChange={onChange} />
                            </Form.Group>
                        }
                        
                        {error !== '' && <><span className="validation-error">{error}</span></>}
                        {resetResponse !== '' &&  <><Alert variant="success">{resetResponse}</Alert></>}
                        { !showReset &&
                            <p className="blueLink" onClick={onForgotClickHandler}>Forgot Password?</p>
                        }
                        { showReset &&
                            <p className="blueLink" onClick={onForgotClickHandler}>Back to Login</p>
                        }
                        
                        
                        <div className="d-grid gap-2">
                            <Button variant="custom" type="submit" size="lg" onClick={onSubmit}>
                               { !showReset ? 'Submit':'Reset Password'}
                            </Button>
                        </div>

                    </Form>
                </div>
            </div>
        </>
    )
}

export default Login;