import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ArcElement, Chart as ChartJS } from "chart.js";
import DoughnutLabel from "chartjs-plugin-doughnutlabel-rebourne";
import React, { useContext, useEffect, useState } from "react";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { FaArrowRight, FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { GiChicken } from "react-icons/gi";
import { BsEgg } from "react-icons/bs";
import Select from "react-select";
import { connectGet } from "../../helpers/Connect";
import AuthContext from "../../store/auth-context";
import ProductionLineChart from '../Chart/productionLineChart';
import ErrorPage from '../Error/ErrorPage';
import LoaderPage from '../Loader/LoaderPage';
import DashboardTimeline from '../Timeline/DashboardTimeline';
import DashBoardDataTable from "./DashBoardDataTable";
import DashBoardPie from "./DashBoardPie";



ChartJS.register(
    ArcElement,
    DoughnutLabel
);



const DashBoardCard = () => {


    const [optionData, setOptionData] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)
    const [dashboardData, setDashboardData] = useState([])
    const [batchId, setBatchId] = useState('')
    const [batchOptionData, setBatchOptionData] = useState([])
    const [selectedBatchOption, setSelectedBatchOption] = useState(null)
    const [timelineData, setTimelineData] = useState([])
    const [timelineDate, setTimeLineDate] = useState([])
    const [showDetailed, setShowDetailed] = useState(false)
    const [filteredDashboardData, setFilteredDashboardData] = useState([])
    const [batchData, setBatchData] = useState([])
    const [loading, setLoading] = useState(false)
    const authCtx = useContext(AuthContext)



    const getFirstDayOfWeek = (d) => {
        const date = new Date(d);
        const day = date.getDay();
        const diff = date.getDate() - day;

        return new Date(date.setDate(diff));
    }

    const toIsoString = (date) => {
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                return (num < 10 ? '0' : '') + num;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    }

    const [selectedDate, setDateData] = useState([getFirstDayOfWeek(new Date()), new Date()]);

    const onChangeDate = (date) => {
        setDateData(date)
        getDashboardData(authCtx.orgId, selectedOption.value, date)
        getBatchData(selectedOption.value, date)
    }

    const populateOptions = (data) => {
        var optionData = data.map(item => ({ value: item.id, label: item.farm_name }))
        setSelectedOption({ value: optionData[0].value, label: optionData[0].label })
        setOptionData(optionData)
        getDashboardData(authCtx.orgId, optionData[0].value, selectedDate)
        getBatchData(optionData[0].value, selectedDate)
    }

    const handleOptionChange = (selectedOptions) => {
        setSelectedOption(selectedOptions)
        filterData(dashboardData, selectedOptions.value, 'farmId')
        getBatchData(selectedOptions.value, selectedDate)
    }


    const getDashboardData = (id, filteredId, dateData) => {
        const finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/dashboard/' + id + '?level=org&startDate=' + toIsoString(dateData[0]).split('T')[0] + '&endDate=' + toIsoString(dateData[1]).split('T')[0]
        console.log(finalUrl)
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                console.log(response.payload)
                setDashboardData(response.payload)
                filterData(response.payload, filteredId, 'farmId')
            })
            .catch(error => {
                console.log(error)
            })
    }

    const getBatchData = (id, dateData) => {
        const finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/dashboard/' + id + '?level=farm&startDate=' + toIsoString(dateData[0]).split('T')[0] + '&endDate=' + toIsoString(dateData[1]).split('T')[0]
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                setBatchData(response.payload)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const filterData = (data, val, key) => {
        const filteredData = data.filter((item) => {
            console.log(item[key].search(val))
            return item[key].search(val) !== -1
        })
        console.log(filteredData)
        setFilteredDashboardData(filteredData)
    }

    const getFarmByOrg = () => {
        const finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/farm/' + authCtx.orgId + '?level=org'
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                populateOptions(response.payload)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })

    }

    const getShedData = (Id) => {
        const shedUrl = process.env.REACT_APP_HAWKEYE_URL + '/batch/shedInfo/' + Id
        connectGet(shedUrl, authCtx.token)
            .then(response => {
                populateBatchOptions(response.payload, Id)

            })
            .catch(error => {
                console.log(error)
            })

    }

    const getDetailedSummary = (building, batchNumber) => {
        const detailedSummaryUrl = process.env.REACT_APP_HAWKEYE_URL
            + '/dailySummary?farmId=' + selectedOption.value
            + '&batchId=' + batchNumber
            + '&buildingId=' + building
            + '&startDate=' + toIsoString(selectedDate[0]).split('T')[0]
            + '&endDate=' + toIsoString(selectedDate[1]).split('T')[0]
            + '&level=org'

        connectGet(detailedSummaryUrl, authCtx.token)
            .then(response => {
                setTimelineData(response.payload)
                var dateOptions = response.payload[0].logByDate.map(item => ({ title: item.logDate }))
                setTimeLineDate(dateOptions)


            })
            .catch(error => {
                console.log(error)
            })
    }


    const viewButtonClickHandler = (batchId) => {
        //console.log(batchId)
        setBatchId(batchId)
        getShedData(batchId)
        setShowDetailed(true)
    }

    const handleBatchOptionChange = (selectedOptions) => {
        setTimeLineDate([])
        setTimelineData([])
        setSelectedBatchOption(selectedOptions)
        getDetailedSummary(selectedOptions.value, batchId)

    }

    const populateBatchOptions = (data, batchNumber) => {
        var optionData = data.map(item => ({ value: item.shed_id, label: item.Buildings.building_name }))
        setBatchOptionData(optionData)
        setSelectedBatchOption({ value: optionData[0].value, label: optionData[0].label })
        getDetailedSummary(optionData[0].value, batchNumber)
    }


    const closeButtonClickHandler = () => {
        setShowDetailed(false)
        setTimeLineDate([])
        setTimelineData([])
    }


    useEffect(() => {
        setLoading(true)
        getFarmByOrg()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <LoaderPage /> :
            <>
                {!showDetailed &&
                    <Container ><br />
                        <Row>
                            <Col>
                                <h4>Dashboard</h4>
                            </Col>
                            <Col>
                                <DateRangePicker onChange={onChangeDate} value={selectedDate} />
                            </Col>
                            <Col>
                                <Select options={optionData}
                                    value={selectedOption}
                                    onChange={handleOptionChange} />
                            </Col>

                        </Row>
                        <br />
                        {filteredDashboardData.length === 0 && <ErrorPage message="No records found!!" />}
                        {filteredDashboardData.length > 0 &&
                            <>
                                <Row>
                                    <Col xl={8} lg={6}>
                                        <Row>
                                            <Col sm={4}>
                                                <Card align="center">
                                                    <Card.Header>
                                                        Production
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <h2>{filteredDashboardData[0].productionRate}%</h2>
                                                        <p>
                                                            {filteredDashboardData[0].productionRate > filteredDashboardData[0].expectedProductionRate
                                                                ? <>
                                                                    <span style={{ color: 'green' }}>
                                                                        <FaLongArrowAltUp />
                                                                        {(filteredDashboardData[0].productionRate - filteredDashboardData[0].expectedProductionRate).toFixed(2)}%
                                                                    </span><br />
                                                                    <span>greater than expected</span>
                                                                </>
                                                                :
                                                                <>
                                                                    <span style={{ color: 'red' }}>
                                                                        <FaLongArrowAltDown />
                                                                        {(filteredDashboardData[0].expectedProductionRate - filteredDashboardData[0].productionRate).toFixed(2)}%
                                                                    </span><br />
                                                                    <span>less than expected</span>
                                                                </>
                                                            }
                                                        </p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm={4}>
                                                <Card align="center">
                                                    <Card.Header>
                                                        Feed Consumed
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <h2>{(filteredDashboardData[0].feed.total_feed)} tons</h2>
                                                        <p>
                                                            {filteredDashboardData[0].feed.bird}g / <GiChicken />
                                                            <br />
                                                            {filteredDashboardData[0].feed.egg}g / <BsEgg />
                                                        </p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm={4}>
                                                <Card align="center">
                                                    <Card.Header>
                                                        Flock Mortality
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <h2>{(filteredDashboardData[0].mortalityRate).toFixed(2)}%</h2>
                                                        <p>
                                                            <span style={{ color: 'red' }}><FaLongArrowAltUp /> 10%</span>
                                                            <br />
                                                            <span>since last week</span>
                                                        </p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row><br />
                                        <Row>
                                            <Col sm={6}>
                                                <Card align="center">
                                                    <Card.Header>
                                                        Egg Stock
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <h5>
                                                            Total Eggs: {(filteredDashboardData[0].eggStock.boxes * 210 + filteredDashboardData[0].eggStock.trays * 30 + filteredDashboardData[0].eggStock.eggs).toLocaleString('en-IN')}
                                                        </h5>
                                                        <Row>
                                                            <Col>
                                                                <h1>
                                                                    <Badge bg="custom" text="dark">
                                                                        {filteredDashboardData[0].eggStock.boxes}<br /><h5>boxes</h5>
                                                                    </Badge>
                                                                </h1>
                                                            </Col>
                                                            <Col>
                                                                <h1>
                                                                    <Badge bg="custom" text="dark">
                                                                        {filteredDashboardData[0].eggStock.trays}<br /><h5>trays</h5>
                                                                    </Badge>
                                                                </h1>
                                                            </Col>
                                                            <Col>
                                                                <h1>
                                                                    <Badge bg="custom" text="dark">
                                                                        {filteredDashboardData[0].eggStock.eggs}<br /><h5>eggs</h5>
                                                                    </Badge>
                                                                </h1>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm={6}>
                                                <Card align="center">
                                                    <Card.Header>
                                                        Necc Egg Rate
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <h5>
                                                                Egg Rate in &#8377;
                                                            </h5>
                                                            <Col>
                                                                <h1>
                                                                    <Badge bg="custom" text="dark">
                                                                        {filteredDashboardData[0].eggRateStat.high}<br /><h5>High</h5>
                                                                    </Badge>
                                                                </h1>
                                                            </Col>
                                                            <Col>
                                                                <h1>
                                                                    <Badge bg="custom" text="dark">
                                                                        {filteredDashboardData[0].eggRateStat.average}<br /><h5>Average</h5>
                                                                    </Badge>
                                                                </h1>
                                                            </Col>
                                                            <Col>
                                                                <h1>
                                                                    <Badge bg="custom" text="dark">
                                                                        {filteredDashboardData[0].eggRateStat.low}<br /><h5>Low</h5>
                                                                    </Badge>
                                                                </h1>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>

                                                </Card>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col xl={4} lg={6}>
                                        <Card align="center">
                                            <Card.Header>
                                                Egg Log
                                            </Card.Header>
                                            <Card.Body>
                                                <DashBoardPie eggBySize={filteredDashboardData[0].eggBySize}
                                                    totalEggs={filteredDashboardData[0].totalEggs.toLocaleString('en-IN')}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <Card.Header>
                                                Batch Data
                                            </Card.Header>
                                            <Card.Body>
                                                <DashBoardDataTable batchData={batchData} viewButtonClickHandler={viewButtonClickHandler} />
                                            </Card.Body>
                                        </Card>

                                    </Col>

                                </Row>




                            </>
                        }

                    </Container>
                }
                {showDetailed && <Container><br />
                    <Row>
                        <Col>
                            <h4><GrClose onClick={closeButtonClickHandler} /> Detailed Summary </h4>
                        </Col>
                        <Col>
                            <Select options={batchOptionData}
                                value={selectedBatchOption}
                                onChange={handleBatchOptionChange} />
                        </Col>
                    </Row>



                    <hr />
                    <br />
                    {timelineData.length > 0 && timelineDate.length > 0 &&
                        <Card>
                            <Card.Header>
                                Overall Summary
                            </Card.Header>

                            <Card.Body>

                                <center>
                                    <Row>

                                        <Col>
                                            <h3>
                                                <Badge bg="custom" text="dark">

                                                    {timelineData[0].totalEggs.toLocaleString('en-IN')}<br /><h5>Total Eggs</h5>
                                                </Badge>
                                            </h3>

                                        </Col>
                                        <Col>
                                            <h3>
                                                <Badge bg={timelineData[0].productionRate < (timelineData[0].expectedProductionRate != null ? timelineData[0].expectedProductionRate : 90) ? "violate" : "nonviolate"} text="dark">
                                                    <Row>
                                                        <Col>
                                                            {timelineData[0].productionRate.toFixed(2).replace(/\.00$/, '')} %<br /><h5>Production</h5>
                                                        </Col>
                                                        <Col>
                                                            <FaArrowRight />
                                                        </Col>
                                                        <Col>
                                                            {timelineData[0].expectedProductionRate != null ? timelineData[0].expectedProductionRate : 90} %<br /><h5>Expected</h5>
                                                        </Col>

                                                    </Row>
                                                </Badge>
                                            </h3>

                                        </Col>
                                        <Col>
                                            <h3>
                                                <Badge bg="custom" text="dark">
                                                    {timelineData[0].mortality}<br /><h5>Mortality</h5>
                                                </Badge>
                                            </h3>

                                        </Col>
                                    </Row>

                                </center>
                            </Card.Body>
                        </Card>
                    }
                    <br />
                    <Card>

                        <Card.Body>
                            <Card.Title>
                                {timelineDate.length !== 0 ? "Daily Summary" : "No Data found!"}
                            </Card.Title>
                            {timelineDate.length !== 0 && <DashboardTimeline timelineDate={timelineDate} timelineData={timelineData} />}
                        </Card.Body>
                    </Card>
                </Container>}
            </>
    )
}

export default DashBoardCard;