import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import React, { useState } from "react";
import { Card, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import FeedLineChart from "../components/Chart/feedLineChart";
import ProductionLineChart from "../components/Chart/productionLineChart";


const Inventory = () => {

    const [selectedDate, onChange] = useState([new Date(), new Date()]);
    const [showModal, setShowModal] = useState(false)

    const onViewClick = () => {
        setShowModal(true)
    }

    return (
        <>
            <Container>
                <br />
                <h3>Inventory</h3>
                <hr />
            </Container>
            <Container>
                <Row>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                Jowar
                            </Card.Header>
                            <Card.Body>
                                <h2>5 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                Maize
                            </Card.Header>
                            <Card.Body>
                                <h2>10 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                Brokens
                            </Card.Header>
                            <Card.Body>
                                <h2 style={{ color: 'red' }}>0.7 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                Stone
                            </Card.Header>
                            <Card.Body>
                                <h2>8 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                Soya
                            </Card.Header>
                            <Card.Body>
                                <h2>2 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                SF
                            </Card.Header>
                            <Card.Body>
                                <h2 style={{ color: 'orange' }}>1 ton</h2>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row><br/>
                <Row>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                GN
                            </Card.Header>
                            <Card.Body>
                                <h2>5 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                DOB
                            </Card.Header>
                            <Card.Body>
                                <h2>10 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                Fish
                            </Card.Header>
                            <Card.Body>
                                <h2 style={{ color: 'red' }}>0.7 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                Rapeseed
                            </Card.Header>
                            <Card.Body>
                                <h2>8 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                Gour Kurma
                            </Card.Header>
                            <Card.Body>
                                <h2>2 tons</h2>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <Card align="center">
                            <Card.Header>
                                DDGS
                            </Card.Header>
                            <Card.Body>
                                <h2 style={{ color: 'orange' }}>1 ton</h2>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
            <Container>
                <br />
                Select Dates &nbsp;<DateRangePicker onChange={onChange} value={selectedDate} />
            </Container><br />
            <Container>
                <Row>
                    <Col sm={4} md={6}>
                        <Card align="center" style={{ height: '23rem' }}>
                            <Card.Header>
                                Feed Consumption (tons)
                            </Card.Header>
                            <Card.Body>
                                <FeedLineChart />
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col sm={4} md={6}>
                        <Card align="center" style={{ height: '23rem' }}>
                            <Card.Header>
                                Egg Production (lakhs)
                            </Card.Header>
                            <Card.Body>
                                <ProductionLineChart />
                            </Card.Body>
                        </Card>

                    </Col>

                </Row>
            </Container><br />
            <Container>
                <Card>
                    <Card.Header>Feed Consumption Log</Card.Header>
                    <Card.Body>
                        <Table striped bordered hovered>
                            <thead>
                                <tr>
                                    <th>Log Date</th>
                                    <th>Feed Consumed(tons)</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2022-05-18</td>
                                    <td>5</td>
                                    <td><FaEye onClick={onViewClick} /></td>
                                </tr>
                                <tr>
                                    <td>2022-05-17</td>
                                    <td>3</td>
                                    <td><FaEye onClick={onViewClick} /></td>
                                </tr>
                                <tr>
                                    <td>2022-05-16</td>
                                    <td>3</td>
                                    <td><FaEye onClick={onViewClick} /></td>
                                </tr>
                                <tr>
                                    <td>2022-05-15</td>
                                    <td>3</td>
                                    <td><FaEye onClick={onViewClick} /></td>
                                </tr>
                                <tr>
                                    <td>2022-05-14</td>
                                    <td>3</td>
                                    <td><FaEye onClick={onViewClick} /></td>
                                </tr>
                                <tr>
                                    <td>2022-05-13</td>
                                    <td>3</td>
                                    <td><FaEye onClick={onViewClick} /></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

            </Container>
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Row>
                            <Col md={12}>
                            <Form.Select aria-label="Default select example">
                            <option>Batch x</option>
                            <option value="1">Batch 1</option>
                            <option value="2">Batch 2</option>
                            <option value="3">Batch 3</option>
                            </Form.Select>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hovered>
                        <thead>
                            <tr>
                                <td>Feed Type</td>
                                <td>Quantity (tons)</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Maize</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>Brokens</td>
                                <td>1.2</td>
                            </tr>
                            <tr>
                                <td>Soya</td>
                                <td>0.2</td>
                            </tr>
                            <tr>
                                <td>GN</td>
                                <td>0.2</td>
                            </tr>
                            <tr>
                                <td>DOB</td>
                                <td>0.4</td>
                            </tr>
                            <tr>
                                <td>Fish</td>
                                <td>0.3</td>
                            </tr>
                            <tr>
                                <td>Rapeseed</td>
                                <td>0.2</td>
                            </tr>
                            <tr>
                                <td>Gaur kurma</td>
                                <td>0.1</td>
                            </tr>
                            <tr>
                                <td>DDGS</td>
                                <td>0.2</td>
                            </tr>
                            <tr>
                                <td>Stone</td>
                                <td>0.1</td>
                            </tr>
                            <tr>
                                <td>Jowar</td>
                                <td>0.1</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>5</td>
                            </tr>
                            
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>

    )

}

export default Inventory