export const connectGet = (url, authToken) => {

    return fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': authToken,
            'Content-Type': 'application/json'
        }
    })
        .then(handleResponse)
}

export const connectPost = (url, data, authToken, methodIdentifier = 'POST') => {

    return fetch(url, {
        method: methodIdentifier,
        body: data,
        headers: {
            'Authorization': authToken,
            'Content-Type': 'application/json'
        }
    })
        .then(handleResponse)
}

export const connectDelete = (url, authToken) => {

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': authToken,
            'Content-Type': 'application/json'
        }
    })
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status)) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                localStorage.removeItem('token');
                window.location = '/login'
            }
            if (response.status >= 500) {
                window.location = '/server'
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}    
