import React from "react";
import { Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";



const DashBoardDataTable = (props) => {
    return(
        <Table striped hover responsive>
        <thead>
            <tr>
                <th>Batch Name</th>
                <th>Week</th>
                <th>Production</th>
                <th>Expected Production</th>
                <th>Mortality</th>
                <th>Total Eggs</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {
                props.batchData.map(item=> {
                    return <tr><td>{item.batchName}</td><td>{item.week}</td><td>{item.productionRate}%</td><td>{item.expectedProductionRate}%</td><td>{item.mortalityRate}%</td><td>{item.totalEggs}</td><td><FaEye onClick={() => props.viewButtonClickHandler(item.batchId)}/></td></tr>
                })
            }
        </tbody>
    </Table>
    )
}

export default DashBoardDataTable