import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    boxes: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0).required('Boxes are required'),
    trays: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0).max(6, 'Max value for trays is 6.').required('Trays are required'),
    loose_eggs: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0).max(29, 'Max value for loose eggs is 29.').required('Loose Eggs Are Required'),
    total_eggs: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).required('Total Eggs Are Required'),
    damaged_eggs: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0).required('Damaged eggs is required'),
    dead: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0).required('Mortality is required'),
    feed: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).default(0).required('Feed is required'),
    logdate: Yup.string()
    .required('Batch start date is required')
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Batch start date must be a valid date in the format YYYY-MM-DD'),
    farm: Yup.string().ensure().required('Farm is required'),
    batch: Yup.string().ensure().required('Batch is required'),
    building: Yup.string().ensure().required('Building is required'),
    size: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).required('Size is required')
  });