import React from "react"
import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const FeedLineChart = (props) => {

    const data = {
        labels: ['2022-05-12','2022-05-13','2022-05-14','2022-05-15','2022-05-16','2022-05-17','2022-05-18' ],
        datasets: [
          {
            label: 'Maize',
            data: [3,2,5,6,7,6,8],
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "#6C63FF"
          },
          {
            label: 'Broken Rice',
            data: [1,0.6,2.5,1.5,3,1,0.7],
            fill: false,
            borderColor: "#C730FD"
          },
          {
            label: 'Stone',
            data: [0.2, 0.5, 0.6, 0.3, 0.8, 0.3, 0.8],
            fill: false,
            borderColor: "#9093ee"
          },
          {
            label: 'Soya',
            data: [1.3, 1.5, 1.8, 1.3, 1.6, 1.7, 1.9],
            fill: false,
            borderColor: "#dc81fe"
          },
          {
            label: 'Grain',
            data: [2.2, 2.6, 2.1, 2.8, 2.9, 3.5, 2.9],
            fill: false,
            borderColor: "#9502ca"
          }

        ]
      };
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            
            labels: {
                boxWidth: 10,
                usePointStyle: false,
                pointStyle: 'circle'
            }
          },
          title: {
            display: false,
            text: 'Feed Consumption',
          },
        },
      };
    return (
        <>
        <Line data={data} options={options}/>
        </>
    )
}

export default FeedLineChart