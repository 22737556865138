import React, { useState } from "react"
import { Button, Card, Col, Container, Form, FormControl, InputGroup, Row, Table } from "react-bootstrap"
import { FaSearch, FaStar } from "react-icons/fa"
import './card.css'
import sample from './sample.png'

const SearchableCard = (props) => {
    const [value, setValue] = useState("");
    return (
        <>
            <br />
            <Container>
                <Form>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><FaSearch /></InputGroup.Text>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            onChange={e => setValue(e.target.value)}
                            value={value}
                        />
                    </InputGroup>
                </Form>
                {props.cardData.length === 0 && <h3>No Data available!</h3>}
            </Container>
            <Container fluid="md">
                <Row md="3">
                    {props.cardData.filter(item => {
                        if(!value) return true
                        if(item.name.includes(value)){
                            return true;
                        }
                        return false;
                    }).map(item => {
                        return (
                            <>
                                <Col md="3">
                                    <Card className="customCard" >
                                        <Card.Body>
                                            <img src={sample} className="img-custom" alt=""></img>
                                            <Card.Title><br />{item.name}</Card.Title>

                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>{item.vendor_category}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phone</td>
                                                        <td>{item.phone_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Address</td>
                                                        <td>{item.vendor_address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rating</td>
                                                        <td><FaStar className="checkedStar" /><FaStar className="checkedStar" /><FaStar className="checkedStar" /><FaStar /><FaStar /></td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            <Button variant="custom" onClick={() => props.buttonClickHandler(item.id, props.buttonName)}>{props.buttonName}</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                        )
                    })}
                </Row>

            </Container>

        </>


    )
}


export default SearchableCard