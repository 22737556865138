import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connectGet, connectPost } from "../../helpers/Connect";
import { validationSchema } from "../../helpers/form-validation/FarmForm";
import Select from "react-select";
import AuthContext from "../../store/auth-context";
import './customError.css';
import { isNullOrUndef } from 'chart.js/helpers';

const AddFarm = (props) => {

    const preloadedValues = {
        farm_name: props.editData.farm_name,

    }
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: preloadedValues
    });


    const [show, setShow] = useState(false)
    const [optionData, setOptionData] = useState([])
    const [selectedOption, setSelectedOption] = useState([isNullOrUndef(props.editData) ? {}:{value: props.editData.zone_id, label:props.editData.zone}])
    const authCtx = useContext(AuthContext)

    const getZones = () => {
        const zoneUrl = process.env.REACT_APP_HAWKEYE_URL + '/eggpack/zones'
        connectGet(zoneUrl, authCtx.token)
        .then(response => {
            var extractedOptions = response.payload.map(item => ({value:item.id, label: item.name}))
            setOptionData(extractedOptions)
        }).catch(error => {
            console.log(error)
        })
    }
    
    const handleOptionChange = (selectedOptions) => {
        setSelectedOption(selectedOptions)
    }

    const onSubmit = formData => {
        let addFarmData;
        let addFarmUrl;
        let methodIdentifier;

        if(Object.keys(props.editData).length === 0){
            addFarmData = JSON.stringify({
                "farm_name": formData.farm_name,
                "farm_org_id": props.orgId,
                "zone_id": selectedOption.value
            })
            addFarmUrl = process.env.REACT_APP_HAWKEYE_URL + "/farm"
            methodIdentifier='POST'

        }else{
            addFarmData = JSON.stringify({
                "farm_name": formData.farm_name,
                "zone_id": selectedOption.value
            })
            addFarmUrl = process.env.REACT_APP_HAWKEYE_URL + "/farm/" + props.editData.id
            methodIdentifier='PATCH'
        }
        connectPost(addFarmUrl, addFarmData, authCtx.token, methodIdentifier)
            .then(response => {
                props.cancelHandler(props.identifier)
            })
            .catch(error => {
                setShow(true)
            })
    }

    useEffect(() => {
        getZones()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>

            <Container>
                <br />
                <h3>{Object.keys(props.editData).length === 0 ? 'Add':'Edit'} Farm</h3>
                <hr />
            </Container>
            <Container>
                <Card >
                    <Card.Body>
                        <Alert variant="danger" show={show}>Internal Error. Please try again later</Alert>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Farm Name</Form.Label>
                                <Form.Control type="text"
                                    name="farm_name"
                                    placeholder="Enter farm name"
                                    {...register('farm_name')} />
                                <span className="validation-error">{errors.farm_name?.message}</span>
                            </Form.Group>
                            <Form.Group className='mb-3' controlId="formBasicText">
                            <Form.Label>Select Zone</Form.Label>
                                <Select placeholder="Select Zone"
                                    options={optionData}
                                    value={selectedOption}
                                    onChange={handleOptionChange} />
                            </Form.Group>
                            <Button variant="custom" type="submit">
                                Submit
                            </Button> &nbsp;
                            <Button variant="customSecondary" type="submit" onClick={() => props.cancelHandler(props.identifier)}>
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )

}

export default AddFarm;