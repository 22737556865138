import React from 'react';
import { Button, Card } from 'react-bootstrap';
import Datatable from "react-data-table-component-with-filter";
import { AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { TiStopwatch } from 'react-icons/ti'
import { FaEdit } from 'react-icons/fa';
import moment from 'moment';
import './customTable.css';
import ErrorPage from '../Error/ErrorPage';


const CustomDataTable = (props) => {
  const finalHeader = []
  if (props.data.length !== 0) {
    const keys = Object.keys(props.data[0])
    keys.map(item => {
      if (props.hiddenColumns.indexOf(item) === -1) {
        const Obj = {}
        Obj.name = item.replaceAll('_', ' ').replaceAll('batch', '')
        Obj.selector = row => row[item]
        Obj.format = row => moment(row[item], 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()  ? moment(row[item]).format('DD-MM-YYYY'): row[item]  
        Obj.sortable = true
        finalHeader.push(Obj)
      }

    })
  }
  
  const actionObj = {}
  actionObj.name = 'Actions'
  actionObj.cell = (row) => 
    <>
      {props.view && <AiOutlineEye onClick={() => props.viewButtonClickHandler(row)}/>}&nbsp;
      {props.edit && <><FaEdit onClick={() => props.editButtonClickHandler(row, props.identifier)} />&nbsp;
      <AiOutlineDelete onClick={() => props.deleteHandler(row.id)} /></>} &nbsp;
      {props.end && row.batch_end === 1 && <TiStopwatch onClick={() => props.endBatchHandler(row.id)} /> }
    </>
  if(props.view || props.edit ){
    finalHeader.push(actionObj)
  }
  


  const customStyles = {
    headRow: {
      style: {
        textTransform: 'uppercase',
        backgroundColor: '#edeafa',
        fontSize: '17px'
      },
    },
    rows: {
      style: {
        fontSize: '16px'
      }

    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  }

  return (
    <>
      <br />
      <Card>
        {props.cardHeader && <Card.Header> Sales Log</Card.Header> }
        
        <Card.Body>
          <Card.Title>
            <Button className="float-end" variant="custom" onClick={() => props.addButtonClickHandler(props.identifier)}>Add {props.identifier}</Button>
          </Card.Title>
          {props.data.length !== 0 ? <Datatable
            columns={finalHeader}
            data={props.data}
            pagination
            highlightOnHover
            pointerOnHover
            customStyles={customStyles}

          /> : <ErrorPage message="No Records Found!!"/>
          }

        </Card.Body>
      </Card>
    </>
  )
}


export default CustomDataTable;