import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import CustomLineChart from "../components/Chart/customLineChart";
import CustomDataTable from "../components/DataTable/CustomDataTable";
import ErrorPage from '../components/Error/ErrorPage';
import AddSale from "../components/Forms/AddSale";
import LoaderPage from '../components/Loader/LoaderPage';
import { connectGet } from "../helpers/Connect";
import { hiden } from "../helpers/datatable-hidden-columns/hidden";
import AuthContext from "../store/auth-context";

const getFirstDayOfWeek = (d) => {
    const date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day;

    return new Date(date.setDate(diff));
}

const toIsoString = (date) => {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}

const Sales = () => {
    const [salesData, setSalesData] = useState([])
    const [showDataTable, setShowDataTable] = useState(true)
    const [showAddSales, setShowAddSales] = useState(false)
    const [statsData, setStatsData] = useState({})
    const [labels, setLabels] = useState([])
    const [firstData, setFirstData] = useState([])
    const [secondData, setSecondData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [vendorData, setVendorData] = useState([])
    const [loading, setLoading] = useState(false) 
    const authCtx = useContext(AuthContext)


    const [selectedDate, setSelectedDate] = useState([getFirstDayOfWeek(new Date()), new Date()]);




    const onChangeDate = (date) => {
        getDashboardData(date)
        getSalesData(date)
        setSelectedDate(date)

    }

    const getSalesData = (dateData) => {
        const saleUrl = process.env.REACT_APP_HAWKEYE_URL + '/saleSummary/' + authCtx.orgId + '?start_date=' + toIsoString(dateData[0]).split('T')[0] + '&end_date=' + toIsoString(dateData[1]).split('T')[0]
        connectGet(saleUrl, authCtx.token)
            .then(response => {
                setSalesData(response.payload)
                setLoading(false)
            }).catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER
                });
            })
    }

    const getDashboardData = (dateData) => {
        setLoading(true)
        const finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/saleSummary/dashboard/' + authCtx.orgId + '?start_date=' + toIsoString(dateData[0]).split('T')[0] + '&end_date=' + toIsoString(dateData[1]).split('T')[0]
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                setStatsData(response.payload)
                if(response.payload.hasOwnProperty('graphData') &&  response.payload.graphData.length === 0){
                    setShowDataTable(false)
                }else{
                    setShowDataTable(true)
                }
                var extLabels = response.payload.graphData.map(item => item.log_date)
                var extFirstData = response.payload.graphData.map(item => item.revenue)
                var extSecondData = response.payload.graphData.map(item => item.vendor_loss)
                setLabels(extLabels)
                setFirstData(extFirstData)
                setSecondData(extSecondData)
            }).catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER
                });
            })
    }
    
    const getVendorData = (id) => {
        const finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/saleSummary/vendorData/' +
            authCtx.orgId +
            '?vendor_id=' +
            id +
            '&start_date=' +
            toIsoString(selectedDate[0]).split('T')[0] +
            '&end_date=' +
            toIsoString(selectedDate[1]).split('T')[0]
        connectGet(finalUrl, authCtx.token)
        .then(response => {
            setVendorData(response.payload)
            setShowModal(true)
        }).catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER
                });
        })
   
    }
    const addButtonClickHandler = () => {
        setShowDataTable(false)
        setShowAddSales(true)
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    const cancelButtonClickHandler = () => {
        setShowDataTable(true)
        setShowAddSales(false)
        getDashboardData(selectedDate)
        getSalesData(selectedDate)

    }

    useEffect(() => {
        
        getDashboardData(selectedDate)
        getSalesData(selectedDate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <LoaderPage/> :
        <>
            {!showAddSales && <> <Container>
                <br />
                <Row>
                    <Col>
                        <h3>Sales Dashboard</h3>
                    </Col>
                    <Col>
                        Select Dates <DateRangePicker onChange={onChangeDate} value={selectedDate} />
                    </Col>
                    <Col>
                        <Button className="float-end" variant="custom" onClick={addButtonClickHandler}>Add Sale</Button>
                    </Col>
                </Row>
                <hr />
            </Container></>}
            {statsData.hasOwnProperty('graphData') &&  statsData.graphData.length === 0 && !showAddSales &&
                <Container>
                    <ErrorPage message="No Records Found!!"/>
                </Container>
            }
            {statsData.hasOwnProperty('graphData') &&  statsData.graphData.length > 0 && !showAddSales &&
                <Container>
                    <Row>
                        <Col>
                            <Card align="center">
                                <Card.Header>
                                    Sold Eggs
                                </Card.Header>
                                <Card.Body>
                                    <h2>{statsData.dashboardData[0].sold_eggs.toLocaleString('en-IN')}</h2>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card align="center">
                                <Card.Header >
                                    Total Revenue
                                </Card.Header>
                                <Card.Body>
                                    <h2>&#8377;{parseFloat(statsData.dashboardData[0].revenue.toFixed(2)).toLocaleString('en-IN')}</h2>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card align="center">
                                <Card.Header >
                                    Vendor Profit/Loss
                                </Card.Header>
                                <Card.Body>
                                    <h2>&#8377;{parseFloat(statsData.dashboardData[0].vendor_loss.toFixed(2)).toLocaleString('en-IN')}</h2>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row><br />
                    <Row>
                        <Col sm={4} md={6}>
                            <Card align="center" style={{ height: '23rem' }}>
                                <Card.Header>
                                    Sales Chart
                                </Card.Header>
                                <Card.Body>
                                    <CustomLineChart labels={labels}
                                        firstLabel="Revenue"
                                        firstData={firstData}
                                        secondLabel="Vendor Profit/Loss"
                                        secondData={secondData} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={4} md={6}>
                            <Card align="center" style={{ height: '23rem', overflowX: 'scroll' }}>
                                <Card.Header>
                                    Sales By Vendor
                                </Card.Header>
                                <Card.Body>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Revenue</th>
                                                <th>Profit/Loss</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {statsData.vendorData.map(item => {
                                                return <>
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{parseFloat(item.revenue.toFixed(2)).toLocaleString('en-IN')}</td>
                                                        <td>{parseFloat(item.vendor_loss.toFixed(2)).toLocaleString('en-IN')}</td>
                                                        <td><FaEye onClick={() => getVendorData(item.id)}/></td>

                                                    </tr>
                                                </>

                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>

                </Container>
            }

            {showAddSales && <AddSale cancelButtonClickHandler={cancelButtonClickHandler} />}
            <Container>
                {showDataTable &&
                    <CustomDataTable data={salesData}
                        hiddenColumns={hiden.sales}
                        identifier="Sale"
                        addButtonClickHandler={addButtonClickHandler}
                        view={false}
                        edit={false}
                        cardHeader={true}
                    />}
            </Container>

            <Modal size="lg" show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Detailed Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {vendorData &&
                        <>
                            
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Sale Date</th>
                                        <th>Eggs Sold</th>
                                        <th>Sale Rate</th>
                                        <th>NECC Rate</th>
                                        <th>Revenue</th>
                                        <th>Profit/Loss</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendorData.map(item => {
                                        const hStyle = { color: 'red' }
                                        const gStyle = { color: 'green'}
                                        return <>
                                        <tr>
                                            <td>{item.log_date}</td>
                                            <td>{item.eggs_sold}</td>
                                            <td style={item.sale_egg_rate < item.necc_eggrate ? hStyle:gStyle}>{item.sale_egg_rate}</td>
                                            <td>{item.necc_eggrate}</td>
                                            <td>{parseFloat(item.revenue.toFixed(2)).toLocaleString('en-IN')}</td>
                                            <td>{parseFloat(item.vendor_loss.toFixed(2)).toLocaleString('en-IN')}</td>
                                        </tr>
                                        </>
                                            })}
                                </tbody>
                            </Table>
                        </>
                    }

                    
                </Modal.Body>
            </Modal>




        </>

    )
}

export default Sales