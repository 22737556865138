import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Chrono } from "react-chrono";
import './card.css';


const DashboardTimeline = (props) => {

    
    return (
        <>
            
            
            <div style={{ width: "100%", height: "60vh" }}>
            <Chrono items={props.timelineDate} mode="VERTICAL" enableOutline borderLessCards={true}>
              {  props.timelineData[0].logByDate.map(item => {
                  return <>
                    <div>
                   <Card className="customCards">
                       <Card.Header>
                           <h5>Total Eggs: {item.totaleggs.toLocaleString('en-IN')}</h5>
                       </Card.Header>
                       <Card.Body>
                           <Row>
                               <Col>
                               <center>
                               <h4>{item.boxes}</h4>
                                <h6>Boxes</h6>
                               </center>
                                        
                               </Col>
                               <Col>
                               <center>
                                <h4>{item.trays}</h4>
                                <h6>Trays</h6>
                               </center>
                               </Col>
                               <Col>
                               <center>
                               <h4>{item.eggs}</h4>
                               <h6>Eggs</h6>
                               </center>
                               </Col>
                               <Col>
                               <center>
                               <h4>{item.damageEggs}</h4>
                               <h6>Damaged</h6>
                               </center>

                               </Col>
                           <Col>
                               <center>
                               <h4>{item.mortality}</h4>
                                <h6>Mortality</h6>
                               </center>
                                        
                               </Col>
                               <Col>
                               <center>
                                <h4>{item.productionRate.toFixed(2).replace(/\.00$/, '')}%</h4>
                                <h6>Production</h6>
                               </center>
                               </Col>
                               <Col>
                               <center>
                               <h4>{item.expectedProductionRate != null ? item.expectedProductionRate : 90}%</h4>
                               <h6>Expected</h6>
                               </center>
                               </Col>
                               <Col>
                               <center>
                               <h4>1</h4>
                               <h6>Feed</h6>
                               </center>

                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
                    
                </div>
                  </>
              })}
                
            </Chrono>
            </div>
            
              
        </>
    )
}


export default DashboardTimeline