import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Firstname is required'),
    lastname: Yup.string().required('Lastname is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    phone_number: Yup.string()
    .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid")
    .required('phone number is required'),
    role: Yup.string().required('Role is required')
    
  });