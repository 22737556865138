import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FcEmptyTrash, FcPlus } from "react-icons/fc";
import { connectGet, connectPost } from "../../helpers/Connect";
import { validationSchema } from "../../helpers/form-validation/BatchForm";
import AuthContext from "../../store/auth-context";
import './customError.css';

const AddBatch = (props) => {

    const preloadedValues = {

    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: preloadedValues
    });


    const [show, setShow] = useState(false)
    const [buildings, setBuildings] = useState([])
    const [inputList, setInputList] = useState([{ shed_id: "", batch_count: "" }])
    const authCtx = useContext(AuthContext)
    const { REACT_APP_HAWKEYE_URL } = process.env

    useEffect(() => {

        const finalUrl = REACT_APP_HAWKEYE_URL + '/building/' + props.farmId + '?level=farm'
        connectGet(finalUrl, authCtx.token)
            .then(response => {
             
                var optionData = response.payload.map(item => ({ value: item.id, label: item.building_name + " Capacity: "+ item.available_capacity }))
                setBuildings(optionData)
            })
            .catch(error => {
                console.log(error)
            })
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { shed_id: "", batch_count: "" }]);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        if (name==='batch_count'){
            list[index][name] = parseInt(value);
        }else{
            list[index][name] = value;
        }
        
        setInputList(list);
    };

    const onSubmit = formData => {
         if(formData.batch_end_date === null){
             const currentDate = new Date(formData.batch_start_date)
             currentDate.setDate(currentDate.getDate() + 90);
             formData['batch_end_date'] = new Date(currentDate)
         }
         var finalData ={...formData, batch_sheds:inputList, farm_id: props.farmId}
         const finalUrl = REACT_APP_HAWKEYE_URL + '/batch'
         connectPost(finalUrl, JSON.stringify(finalData), authCtx.token)
            .then(response => {
                props.cancelHandler()
            })
            .catch(error => {
                setShow(true)
            })
    }

    return (
        <>

            <Container>
                <br />
                <h3>{Object.keys(props.editData).length === 0 ? 'Add' : 'Edit'} Batch</h3>
                <hr />
            </Container>
            <Container>
                <Card >
                    <Card.Body>
                        <Alert variant="danger" show={show}>Internal Error. Please try again later</Alert>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Batch Name</Form.Label>
                                <Form.Control type="text"
                                    name="batch_name"
                                    placeholder="Enter batch name"
                                    {...register('batch_name')} />
                                <span className="validation-error">{errors.batch_name?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Batch Capacity</Form.Label>
                                <Form.Control type="text"
                                    name="batch_capacity"
                                    placeholder="Enter batch capacity"
                                    {...register('batch_capacity')} />
                                <span className="validation-error">{errors.batch_capacity?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Breed</Form.Label>
                                <Form.Control type="breed"
                                    name="text"
                                    placeholder="Enter breed"
                                    {...register('breed')} />
                                <span className="validation-error">{errors.breed?.message}</span>
                            </Form.Group>
                            <Row className="align-items-center">
                            <Col>
                                    <Form.Group className="mb-3" controlId="formBasicNumber">
                                        <Form.Label>Origin Date</Form.Label>
                                        <Form.Control type="date"
                                            name="origin_date"
                                            {...register('origin_date')} />
                                        <span className="validation-error">{errors.origin_date?.message}</span>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicNumber">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control type="date"
                                            name="batch_start_date"
                                            {...register('batch_start_date')} />
                                        <span className="validation-error">{errors.batch_start_date?.message}</span>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicNumber">
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control type="date"
                                            name="batch_end_date"
                                            {...register('batch_end_date')} />
                                        <span className="validation-error">{errors.batch_end_date?.message}</span>
                                    </Form.Group>
                                </Col>
                            </Row>



                            <h4>Housing</h4>

                            {
                                inputList.map((x, i) => {
                                    return (
                                        <>
                                            <br />

                                            <Row className="align-items-center" key={i}>
                                                &nbsp;&nbsp;
                                                <Col >
                                                    <Form.Select name="shed_id" onChange={e => handleInputChange(e, i)}>
                                                        <option>select building..</option>
                                                        {buildings.map((countryReg) => <option value={countryReg.value} selected={countryReg.label === x.option}>{countryReg.label}</option>)}
                                                    </Form.Select>
                                                </Col>

                                                <Col  >

                                                    <Form.Control
                                                        type="number"
                                                        className="mb-2"
                                                        name="batch_count"
                                                        placeholder="batch count"
                                                        value={x.batch_count}
                                                        onChange={e => handleInputChange(e, i)}
                                                    />
                                                </Col>
                                                <Col xs lg="2" >
                                                    {inputList.length !== 1 && <FcEmptyTrash size={30} onClick={() => handleRemoveClick(i)} />}
                                                    &nbsp;
                                                    {inputList.length - 1 === i && <FcPlus size={30} onClick={handleAddClick} />}
                                                </Col>
                                            </Row>

                                        </>

                                    )
                                })
                            }<br />
                            <Button variant="custom" type="submit">
                                Submit
                            </Button> &nbsp;
                            <Button variant="customSecondary" type="submit" onClick={() => props.cancelHandler(props.identifier)}>
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )

}

export default AddBatch;