import React from "react";
import { Modal, Table } from "react-bootstrap";

const PlainModal = (props) => {
  
  return (
    <>
      <Modal size="lg" show={props.show} onHide={props.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { props.batchShedData &&
              <>
              <h5>Building Information</h5>
              <Table striped bordered hover>
                  <thead>
                      <tr>
                          <th>Building Name</th>
                          <th>Building Type</th>
                          <th>Building Capacity</th>
                          <th>Batch Capacity</th>
                      </tr>
                  </thead>
                  <tbody>
                      {props.batchShedData.map(item => {
                        return <tr><td>{item.Buildings.building_name}</td><td>{item.Buildings.building_type}</td><td>{item.Buildings.building_capacity}</td><td>{item.batch_count}</td></tr>
                      })}
                  </tbody>
              </Table>
              </>
          }
        
          {props.passedComponent}
          </Modal.Body>
      </Modal>
    </>
  )
}

export default PlainModal