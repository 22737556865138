import React from "react";
import { Container } from "react-bootstrap";
import ErrorPage from "../components/Error/ErrorPage";

const ServerError = () => {
    return (
        <Container>
           <ErrorPage message="Oops! something is broken. Please try again later. If this persists contact support@poultrypack.com" code={500}/>
        </Container>
        
    )
}

export default ServerError