import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    batch_name: Yup.string().required('Batch name is required'),
    batch_capacity: Yup.number().transform(value =>
      isNaN(value) ? undefined : value
    ).required('Batch capacity is required'),
    breed: Yup.string().required('Breed is required'),
    batch_start_date: Yup.string()
    .required('Batch start date is required')
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Batch start date must be a valid date in the format YYYY-MM-DD'),
    batch_end_date: Yup.string()
    .optional('Batch end date is required')
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Batch start date must be a valid date in the format YYYY-MM-DD'),
    origin_date: Yup.string()
    .required('origin date is required')
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Origin date must be a valid date in the format YYYY-MM-DD')
    
  });