import React from "react";
import { Chrono } from "react-chrono";



const Timeline = () => {

    const data = [
        {
          title: "21 Dec 2020",
          cardTitle: "Batch Start",
          cardSubtitle:"Batch started ",
          cardDetailedText: `On 21 Dec 2020, batch was started with 3000 chicks`
        },
        {
            title: "15 Jan 2021",
            cardTitle: "Batch Moved to Grower Shed",
            cardSubtitle:"Batch Moved ",
            cardDetailedText: `On 21 Dec 2020, batch was moved  to egg layind shed  with 2800 chicken` 
        },
        {
            title: "17 Jan 2021",
            cardTitle: "Vaccination",
            cardSubtitle:"1st dose vaccination",
            cardDetailedText: `On 17 Jan 2021, vaccinated chicken`
        },
        {

          title: "18 Feb 2021",
          cardTitle: "Batch moved to xyz",
          cardSubtitle:"Batch moved to xyz ",
          cardDetailedText: `On 18 Feb 2021, batch was started with 3000 chicks`
        },
        {
            title: "19 Feb 2021",
            cardTitle: "Vaccination",
            cardSubtitle:"2nd dose vaccination",
            cardDetailedText: `On 17 Jan 2021, vaccinated chicken`
        },
        {
            title: "20 Mar 2021",
            cardTitle: "Cutting",
            cardSubtitle:"Batch completed",
            cardDetailedText: `On 20 Mar 2021, Batch completed`
        },
        
      ];

    return (
        <>
            
            <h5>Batch Events</h5>
            <div style={{ width: "100%", height: "60vh" }}>
            <Chrono items={data} mode="VERTICAL" enableOutline />
            </div>
            
              
        </>
    )
}


export default Timeline