import React from "react"
import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const CustomLineChart = (props) => {

    const data = {
        labels: props.labels,
        datasets: [
          {
            label: props.firstLabel,
            data: props.firstData,
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "#6C63FF"
          },
          {
            label: props.secondLabel,
            data: props.secondData,
            fill: false,
            borderColor: "#C730FD"
          }
        ]
      };
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            
            labels: {
                boxWidth: 10,
                usePointStyle: false,
                pointStyle: 'circle'
            }
          },
          title: {
            display: false,
            text: 'Revenue',
          },
        },
      };
    return (
        <>
        <Line data={data} options={options}/>
        </>
    )
}

export default CustomLineChart