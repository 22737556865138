import React from "react";
import { ArcElement, Chart as ChartJS } from "chart.js";
import DoughnutLabel from "chartjs-plugin-doughnutlabel-rebourne";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
    ArcElement,
    DoughnutLabel
);

const DashBoardPie = (props) => {

    const data = {
        labels: props.eggBySize.map(function(item){return item['type']}),
        datasets: [{
            label: 'My First Dataset',
            data: props.eggBySize.map(function(item){return item['eggs']}),
            backgroundColor: [
                "#21397b",
                "#a209b5",
                "#001555",
                "#2c4de0",
                "#490049",
                "#804b81"
            ],
            hoverOffset: 4
        }],


    };

    const options = {
        cutout: '70%',
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    padding: 20,
                    pointStyle: 'circle',
                    usePointStyle: true
                }
            },
            title: {
                display: false,
                text: 'Egg Data',
                color: 'black',
                font: {
                    size: 28
                },
                padding: {
                    top: 30,
                    bottom: 30
                },

                responsive: true,
                animation: {
                    animateScale: true,
                }
            },
            doughnutlabel: {
                labels: [{
                    text: props.totalEggs,
                    font: {
                        size: 40,
                        weight: 'bold'
                    }
                }, {
                    text: 'Total'
                }]
            }
        }

    }

    return (
        <div style={{ width: '20em' }}>
            <Doughnut data={data} options={options} />
         </div>
    )

}

export default DashBoardPie