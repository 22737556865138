import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useState } from "react";
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connectPost } from "../../helpers/Connect";
import { validationSchema } from "../../helpers/form-validation/UserForm";
import Role from "../../helpers/Role";
import AuthContext from "../../store/auth-context";
import './customError.css';

const AddUser = (props) => {
    const preloadedValues = {
        firstname: props.editData.firstname,
        lastname: props.editData.lastname,
        email: props.editData.email,
        phone_number: props.editData.phone_number

    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: preloadedValues
    });


    const [show, setShow] = useState(false)
    const authCtx = useContext(AuthContext)

    const onSubmit = formData => {
        let userData;
        let userUrl;
        let methodIdentifier;

        if(Object.keys(props.editData).length === 0){
            userData = JSON.stringify({
                "firstname": formData.firstname,
                "lastname": formData.lastname,
                "phone_number": formData.phone_number,
                "password": "test123!",
                "email": formData.email,
                "role": formData.role,
                "org_id": props.orgId
            })
            userUrl = process.env.REACT_APP_HAWKEYE_URL + "/auth/register"
            methodIdentifier='POST'
        }else{
            userData = JSON.stringify({
                "firstname": formData.firstname,
                "lastname": formData.lastname,
                "phone_number": formData.phone_number,
                "email": formData.email,
            })
            userUrl = process.env.REACT_APP_HAWKEYE_URL + "/auth/users/" + props.editData.id
            methodIdentifier='PATCH'
        }
        

        connectPost(userUrl, userData, authCtx.token, methodIdentifier)
            .then(response => {
                var resetBody = JSON.stringify({
                    "username": formData.phone_number
                })
                var resetHeaders = new Headers();
                resetHeaders.append("Content-Type", "application/json");
                var resetRequestOptions = {
                    method: 'POST',
                    headers: resetHeaders,
                    body: resetBody
                }
                fetch(process.env.REACT_APP_HAWKEYE_URL+ "/auth/resetPassword", resetRequestOptions)
                .then(response => {return response.text()})
                .then(data => {
                    console('success')
                })
                .catch(error => console.log(error))
                props.cancelHandler(props.identifier)
            })
            .catch(error => {
                setShow(true)
            })
    }
    
    return (
        <>

            <Container>
                <br />
                <h3>{Object.keys(props.editData).length === 0 ? 'Add':'Edit'} User</h3>
                <hr />
            </Container>
            <Container>
                <Card >
                    <Card.Body>
                        <Alert variant="danger" show={show}>Internal Error. Please try again later</Alert>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text"
                                    name="firstname"
                                    placeholder="Enter first name"
                                    {...register('firstname')} />
                                <span className="validation-error">{errors.firstname?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text"
                                    name="lastname"
                                    placeholder="Enter last name"
                                    {...register('lastname')} />
                                <span className="validation-error">{errors.lastname?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    {...register('email')} />
                                <span className="validation-error">{errors.email?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number"
                                    name="phone_number"
                                    placeholder="Enter phone number"
                                    {...register('phone_number')} />
                                <span className="validation-error">{errors.phone_number?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Role</Form.Label>
                                <Form.Select name="role" placeholder="Select role" {...register('role')}>
                                    <option selected disabled>Select role</option>
                                    <option value={Role.Admin}>{Role.Admin}</option>
                                    <option value={Role.User}>{Role.User}</option>
                                </Form.Select>
                                <span className="validation-error">{errors.size_indicator?.message}</span>
                            </Form.Group>
                            <Button variant="custom" type="submit">
                                Submit
                            </Button> &nbsp;
                            <Button variant="customSecondary" type="submit" onClick={() => props.cancelHandler(props.identifier)}>
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )

}

export default AddUser;