import React from "react";
import { Container } from "react-bootstrap";
import ErrorPage from "../components/Error/ErrorPage";

const NotFoundPage = () => {
    return (
        <Container>
           <ErrorPage message="404 Page Not Found"/>
        </Container>
        
    )
}

export default NotFoundPage