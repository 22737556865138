import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm  } from 'react-hook-form';
import Select from "react-select";
import { connectGet, connectPost } from "../../helpers/Connect";
import { validationSchema } from "../../helpers/form-validation/EggLogForm";
import Role from '../../helpers/Role';
import AuthContext from "../../store/auth-context";
import './customError.css';

const AddEggLog = (props) => {

    const [orgOptionData, setOrgOptionData] = useState([])
    const [farmOptionData, setFarmOptionData] = useState([])
    const [batchOptionData, setBatchOptionData] = useState([])
    const [shedOptionData, setShedOptionData] = useState([])
    const [sizeOptionData, setSizeOptionData] = useState([])
    const [selectedOrgOption, setSelectedOrgOption] = useState([])
    const [selectedFarmOption, setSelectedFarmOption] = useState(null)
    const [selectedBatchOption, setSelectedBatchOption] = useState(null)
    const [selectedShedOption, setSelectedShedOption] = useState(null)
    const [selectedSizeOption, setSelectedSizeOption] = useState(null)

    const authCtx = useContext(AuthContext)


    const {
        register,
        control,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const getDropDownData = (identifier, level, id) => {
        let finalUrl
        if (identifier === 'building') {
            finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/batch/shedInfo/' + id
        } else if (identifier === 'org') {
            finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/organization'
        } else {
            finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/' + identifier + '/' + id + '?level=' + level
        }

        connectGet(finalUrl, authCtx.token)
            .then(response => {
                var selectData
                if (identifier === 'farm') {
                    selectData = response.payload.map(item => ({ value: item.id, label: item.farm_name }))
                    setFarmOptionData(selectData)
                } else if (identifier === 'batch') {
                    selectData = response.payload.map(item => ({ value: item.id, label: item.batch_name }))
                    setBatchOptionData(selectData)
                } else if (identifier === 'org') {
                    selectData = response.payload.map(item => ({ value: item.id, label: item.organization_name }))
                    setOrgOptionData(selectData)
                } else {
                    selectData = response.payload.map(item => ({ value: item.shed_id, label: item.Buildings.building_name }))
                    setShedOptionData(selectData)
                }


            })
            .catch(error => {
                console.log(error)
            })
    }

    const getSizeDropDown = () => {
        const finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/entityList/1'
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                let selectData = response.payload.map(item => ({ value: item.id, label: item.name }))
                setSizeOptionData(selectData)
            })
    }

    const handleTotalEggs = (e) => {
        if (e.target.name === 'total_eggs'){
         var eggsTotalBySize = e.target.value
         var boxes = Math.floor(eggsTotalBySize/210)
         var trays = Math.floor((eggsTotalBySize %210)/30) 
         var eggs = Math.floor((eggsTotalBySize %210) % 30)
         setValue("boxes", boxes)
         setValue("trays", trays)
         setValue("loose_eggs", eggs)
        }else{
            let total_eggs
            let boxes
            let trays
            let loose_eggs
            if(e.target.name === 'boxes'){
              boxes = e.target.value * 210
              trays = getValues().trays != null ? getValues().trays * 30 : 0
              loose_eggs = getValues().loose_eggs != null ? getValues().loose_eggs: 0
            }else if(e.target.name === 'trays'){
             boxes = getValues().boxes != null ? getValues().boxes * 210 : 0
             trays = e.target.value * 30
             loose_eggs = getValues().loose_eggs != null ? getValues().loose_eggs: 0
            }else {
             boxes = getValues().boxes != null ? getValues().boxes * 210 : 0
             trays = getValues().trays != null ? getValues().trays * 30 : 0
             loose_eggs = e.target.value * 1
            }
            total_eggs = boxes + trays + loose_eggs
            setValue("total_eggs", total_eggs)
        }  
     }

    const onSubmit = farmData => {
        const egg_count = {
            boxes: farmData.boxes,
            trays: farmData.trays,
            loose_eggs: farmData.loose_eggs,
            total_eggs: farmData.total_eggs,
            size_indicator: selectedSizeOption.value
        }
        const finalObj = {
            farm_id: selectedFarmOption.value,
            batch_id: selectedBatchOption.value,
            shed_id: selectedShedOption.value,
            logdate: farmData.logdate,
            egg_count: [egg_count],
            damaged_eggs: farmData.damaged_eggs,
            dead: farmData.dead,
            feed: farmData.feed,
            today_egg_rate: 0,
            currency: "inr"
        }
        console.log(finalObj)
        const finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/eggpack'
        connectPost(finalUrl, JSON.stringify(finalObj), authCtx.token)
            .then(response => {
                props.cancelButtonClickHandler(true)
                props.updateSearchHandler(selectedFarmOption, selectedBatchOption, selectedShedOption)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const handleOrgOptionChange = (selectedOptions) => {
        setSelectedOrgOption(selectedOptions)
        setSelectedFarmOption(null)
        setSelectedBatchOption(null)
        setSelectedShedOption(null)
        getDropDownData('farm', 'org', selectedOptions.value)

    }
    const handleOptionChange = (selectedOptions) => {
        setSelectedFarmOption(selectedOptions)
        setSelectedBatchOption(null)
        setSelectedShedOption(null)
        getDropDownData('batch', 'farm', selectedOptions.value)
    }

    const handleBatchOptionChange = (selectedOptions) => {
        setSelectedBatchOption(selectedOptions)
        getDropDownData('building', 'farm', selectedOptions.value)
    }

    const handleShedOptionChange = (selectedOptions) => {
        setSelectedShedOption(selectedOptions)
    }

    const handleSizeOptionChange = (selectedOptions) => {
        setSelectedSizeOption(selectedOptions)
    }

    useEffect(() => {
        if (authCtx.role === Role.SuperAdmin) {
            getDropDownData('org', 'org', authCtx.orgId)
        } else {
            getDropDownData('farm', 'org', authCtx.orgId)
        }
        getSizeDropDown()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Container>
                <br />
                <h3>Add Daily Egg Log</h3>
                <hr />
            </Container>
            <Container>
                <Card >
                    <Card.Body>
                        <Alert variant="danger" show={false}>Internal Error. Please try again later</Alert>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <h4>General Info</h4>
                            {
                                authCtx.role === Role.SuperAdmin && <Form.Group className="mb-3" controlId="formBasicText">
                                    <Select placeholder="select org"
                                        options={orgOptionData}
                                        value={selectedOrgOption}
                                        onChange={handleOrgOptionChange} />
                                </Form.Group>
                            }
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Controller
                                    control={control}
                                    defaultValue={selectedFarmOption}
                                    name="farm"
                                    render={({ field: { onChange,value, ref } }) => (
                                        <Select
                                        placeholder="Select Farm"
                                            options={farmOptionData}
                                            selected={value}
                                            onChange={val => {
                                                onChange(val.value)
                                                handleOptionChange(val)

                                            }}
                                        />
                                    )}
                                />
                                <span className="validation-error">{errors.farm?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Controller
                                    control={control}
                                    defaultValue={selectedBatchOption}
                                    name="batch"
                                    render={({ field: { onChange,value, ref } }) => (
                                        <Select
                                        placeholder="Select Batch"
                                            options={batchOptionData}
                                            selected={value}
                                            onChange={val => {
                                                onChange(val.value)
                                                handleBatchOptionChange(val)

                                            }}
                                        />
                                    )}
                                />
                                <span className="validation-error">{errors.batch?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Controller
                                    control={control}
                                    defaultValue={selectedShedOption}
                                    name="building"
                                    render={({ field: { onChange,value, ref } }) => (
                                        <Select
                                        placeholder="Select Building"
                                            options={shedOptionData}
                                            selected={value}
                                            onChange={val => {
                                                onChange(val.value)
                                                handleShedOptionChange(val)

                                            }}
                                        />
                                    )}
                                />
                                <span className="validation-error">{errors.building?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Control type="date"
                                    name="logdate"
                                    placeholder="select date"
                                    {...register('logdate')}
                                />
                                <span className="validation-error">{errors.logdate?.message}</span>
                            </Form.Group>
                            <h4>Egg Log</h4>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Controller
                                    control={control}
                                    defaultValue={selectedSizeOption}
                                    name="size"
                                    render={({ field: { onChange,value, ref } }) => (
                                        <Select
                                        placeholder="Select Egg Size"
                                            options={sizeOptionData}
                                            selected={value}
                                            onChange={val => {
                                                onChange(val.value)
                                                handleSizeOptionChange(val)

                                            }}
                                        />
                                    )}
                                />
                                <span className="validation-error">{errors.size?.message}</span>
                            </Form.Group>
                            <Row className="align-items-center">
                            <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">
                                    <Controller
                                    control={control}
                                    name="boxes"
                                    render={({field: {onChange, ...field}}) => {
                                        return <Form.Control type="number"
                                        name="boxes"
                                        placeholder="Boxes"
                                        onChange={(v) => {
                                            onChange(v);
                                            handleTotalEggs(v);
                                          }}
                                        {...field}
                                    />
                                    }}
                                    />
                                        <span className="validation-error">{errors.boxes?.message}</span>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">
                                    <Controller
                                    control={control}
                                    name="trays"
                                    render={({field: {onChange, ...field}}) => {
                                        return <Form.Control type="number"
                                        name="trays"
                                        placeholder="trays"
                                        max="6"
                                        onChange={(v) => {
                                            onChange(v);
                                            handleTotalEggs(v);
                                          }}
                                        {...field}
                                    />
                                    }}
                                    />
                                        <span className="validation-error">{errors.trays?.message}</span>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">

                                    <Controller
                                    control={control}
                                    name="loose_eggs"
                                    render={({field: {onChange, ...field}}) => {
                                        return <Form.Control type="number"
                                        name="loose_eggs"
                                        placeholder="loose eggs"
                                        max="29"
                                        onChange={(v) => {
                                            onChange(v);
                                            handleTotalEggs(v);
                                          }}
                                        {...field}
                                    />
                                    }}
                                    />
                                        <span className="validation-error">{errors.loose_eggs?.message}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Controller
                                    control={control}
                                    name="total_eggs"
                                    render={({field: {onChange, ...field}}) => {
                                        return <Form.Control type="number"
                                        name="total_eggs"
                                        placeholder="Total Eggs"
                                        onChange={(v) => {
                                            onChange(v);
                                            handleTotalEggs(v);
                                          }}
                                        {...field}
                                    />
                                    }}
                                    />
                                
                                <span className="validation-error">{errors.total_eggs?.message}</span>
                            </Form.Group>
                            <h4>Other Information</h4>
                            <Row className="align-items-center">
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">

                                        <Form.Control type="number"
                                            name="damaged_eggs"
                                            placeholder="Damaged Eggs"
                                            {...register('damaged_eggs')}

                                        />
                                        <span className="validation-error">{errors.damaged_eggs?.message}</span>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">

                                        <Form.Control type="number"
                                            name="dead"
                                            placeholder="Flock Mortality"
                                            {...register('dead')}
                                        />
                                        <span className="validation-error">{errors.dead?.message}</span>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">

                                        <Form.Control type="number"
                                            name="feed"
                                            placeholder="Feed Quantity"
                                            {...register('feed')}
                                        />
                                        <span className="validation-error">{errors.feed?.message}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="custom" type="submit">
                                Submit
                            </Button> &nbsp;
                            <Button variant="customSecondary" onClick={() => props.cancelButtonClickHandler(false)}>
                                Cancel
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}


export default AddEggLog;