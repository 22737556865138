import React, { useContext, useEffect, useState } from "react";
import { Card, Container, Form, Button, Row, Col } from "react-bootstrap";
import CustomDataTable from "../components/DataTable/CustomDataTable";
import LogView from "../components/EggLogView/LogView";
import AddEggLog from "../components/Forms/AddEggLog";
import PlainModal from "../components/Modal/PlainModel";
import { connectGet } from "../helpers/Connect";
import Select from "react-select";
import { hiden } from "../helpers/datatable-hidden-columns/hidden";
import AuthContext from "../store/auth-context";


function EggLog() {
    const [showTable, setShowTable] = useState(true)
    const [eggLogData, setEggLogData] = useState([])
    const [rowData, setRowData] = useState({})
    const [farmOptionData, setFarmOptionData] = useState([])
    const [batchOptionData, setBatchOptionData] = useState([])
    const [shedOptionData, setShedOptionData] = useState([])
    const [selectedFarmOption, setSelectedFarmOption] = useState(null)
    const [selectedBatchOption, setSelectedBatchOption] = useState(null)
    const [selectedShedOption, setSelectedShedOption] = useState(null)
    const [showPlainModal, setShowPlainModal] = useState(false)
    const authCtx = useContext(AuthContext)

    const getLogData = (finalUrl) => {
        //const finalUrl = process.env.REACT_APP_HAWKEYE_URL + "/eggpack?interval=7"
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                setEggLogData(response.payload)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const getDropDownData = (identifier, level, id) => {
        let finalUrl
        if(identifier === 'building'){
            finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/batch/shedInfo/' + id
        }else{
            finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/' + identifier + '/' + id + '?level=' + level
        }
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                var selectData
                if (identifier === 'farm') {
                    selectData = response.payload.map(item => ({ value: item.id, label: item.farm_name }))
                    setFarmOptionData(selectData)
                } else if (identifier === 'batch') {
                    selectData = response.payload.map(item => ({ value: item.id, label: item.batch_name }))
                    setBatchOptionData(selectData)
                } else {
                    selectData = response.payload.map(item => ({ value: item.shed_id, label: item.Buildings.building_name }))
                    setShedOptionData(selectData)
                }

            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleOptionChange = (selectedOptions) => {
        setSelectedFarmOption(selectedOptions)
        setSelectedBatchOption(null)
        setSelectedShedOption(null)
        getDropDownData('batch', 'farm', selectedOptions.value)
    }

    const handleBatchOptionChange = (selectedOptions) => {
        setSelectedBatchOption(selectedOptions)
        getDropDownData('building', 'farm', selectedOptions.value)
    }

    const handleShedOptionChange = (selectedOptions) => {
        setSelectedShedOption(selectedOptions)

    }

    useEffect(() => {
        //getLogData()
        getDropDownData('farm', 'org', authCtx.orgId)
       
      // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [])

    useEffect(() => {
        if (Object.keys(rowData).length > 0) {
            setShowPlainModal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [rowData])

    const addButtonClickHandler = () => {
        setShowTable(!showTable)
    }

    const cancelButtonClickHandler = (refresh) => {
        setShowTable(!showTable)
        if (refresh) {
            getLogData()
        }

    }

    const viewButtonClickHandler = (row) => {
        setRowData(row)


    }

    const handlePlainModalClose = () => {
        setShowPlainModal(false)
    }

    const updateSearchHandler = (farm, batch, building) => {
        setSelectedFarmOption(farm)
        setSelectedBatchOption(batch)
        setSelectedShedOption(building)
        let finalUrl = process.env.REACT_APP_HAWKEYE_URL + "/eggpack?interval=7"
        finalUrl+="&farmId="+farm.value
        finalUrl+="&batchId="+batch.value
        finalUrl+="&buildingId="+building.value
        getLogData(finalUrl)

    }

    const handleSearch = (e) => {
        e.preventDefault()
        let finalUrl = process.env.REACT_APP_HAWKEYE_URL + "/eggpack?interval=7"
        if(selectedFarmOption != null){
            finalUrl+="&farmId="+selectedFarmOption.value
        }
        if(selectedBatchOption != null){
            finalUrl+="&batchId="+selectedBatchOption.value
        }
        if(selectedShedOption != null){
            finalUrl+="&buildingId="+selectedShedOption.value
        }
        getLogData(finalUrl)
    }

    return (
        <>
            {!showTable && <AddEggLog cancelButtonClickHandler={cancelButtonClickHandler}  updateSearchHandler={updateSearchHandler}/>}
            {showTable &&
                <>
                    <Container>
                        <br />
                        <h3>Daily Egg Log</h3><hr />
                        <Card>
                            <Card.Body>
                                <br />
                                <Form >
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicText">

                                                <Select placeholder="select farm"
                                                    options={farmOptionData}
                                                    value={selectedFarmOption}
                                                    onChange={handleOptionChange}
                                                    isClearable={true} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicText">

                                                <Select placeholder="Select Batch"
                                                    options={batchOptionData}
                                                    value={selectedBatchOption}
                                                    onChange={handleBatchOptionChange}
                                                    isClearable={true}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicText">

                                                <Select placeholder="Select Building"
                                                    options={shedOptionData}
                                                    value={selectedShedOption}
                                                    onChange={handleShedOptionChange}
                                                    isClearable={true} />
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Button variant="custom"  onClick={handleSearch}>
                                                Search
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <Container>
                        <CustomDataTable data={eggLogData}
                            hiddenColumns={hiden.eggLog}
                            identifier="Egg Log"
                            view={true}
                            addButtonClickHandler={addButtonClickHandler}
                            viewButtonClickHandler={viewButtonClickHandler}
                        />
                    </Container>
                </>
            }
            <PlainModal show={showPlainModal}
                title="Egg Log Data"
                passedComponent={<LogView data={rowData} />}
                handleModalClose={handlePlainModalClose}
            />


        </>

    )
}


export default EggLog;