import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
  Tooltip
} from 'chart.js';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import './dashboard.css';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

function Dashboard() {
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
          {
            label: "First dataset",
            data: [33, 53, 85, 41, 44, 65],
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "#6C63FF"
          },
          {
            label: "Second dataset",
            data: [33, 25, 35, 51, 54, 76],
            fill: false,
            borderColor: "#C730FD"
          }
        ]
      };
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Revenue',
          },
        },
      };
      
    return (
        <>
       
        <Container>
            <br/>
            <h3>Dashboard</h3>
        </Container>
        <Container>
            <Row>
                <Col lg={true}><Card className="text-center" >
                <Card.Header><b>Customers</b></Card.Header>
                <Card.Body>
                    <Card.Title><h2>100</h2></Card.Title>
                </Card.Body>
            </Card></Col>
            <Col lg={true}>
            <Card className="text-center" >
                <Card.Header>Revenue</Card.Header>
                <Card.Body>
                    <Card.Title><h2>5,00,000</h2></Card.Title>
                    
                </Card.Body>
            </Card>
            </Col>
            <Col lg={true}>
            <Card className="text-center" >
                <Card.Header>License About to Expire</Card.Header>
                <Card.Body>
                    <Card.Title><h2>10</h2></Card.Title>
                </Card.Body>
            </Card>
            </Col>
            </Row>
        
            
            
        </Container>
        <br/>
        <Container>
            <Row>
                <Col sm={4} md={6}>
                    <Card>
                        <Card.Body>
                        <Line data={data} options={options}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4} md={6}>
                    <Card>
                        <Card.Body>
                        <Line data={data} options={options}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
            
        
        </>
    )
}

export default Dashboard;