import React from "react";
import { Modal, Button } from "react-bootstrap";

const CustomModal = (props) => {
  
  return (
    <>
      <Modal show={props.show} onHide={props.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleModalClose}>
            Close
          </Button>
          <Button variant="danger" onClick={props.handlerFunction}>
            
            {props.deleteButton!== undefined ? props.deleteButton : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CustomModal