import React, { useContext, useEffect } from 'react';
import './App.css';
import Navigation from './components/Navbar/Navigation';
import { Routes, Route, useLocation} from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Organization from './pages/Organization';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ViewOrg from './pages/ViewOrg';
import EggLog from './pages/EggLog';
import NotFoundPage from './pages/NotFoundPage';
import AuthContext from './store/auth-context';
import Role from './helpers/Role';
import DashBoardCard from './components/DashboardCard/DashBoardCard';
import Vendor from './pages/Vendor';
import VendorSearch from './pages/VendorSearch';
import Sales from './pages/Sales';
import {GlobalDebug} from './helpers/remove-console'
import Inventory from './pages/Inventory';
import ServerError from './pages/ServerError';



function App() {
  const location = useLocation();
  const authCtx = useContext(AuthContext)

  useEffect(() => {
    (process.env.REACT_APP_ENV === "PRODUCTION") &&
      GlobalDebug(false);
  }, []);
  return (
    <>
      {location.pathname !== '/login' && <Navigation />}
      <Routes>
        <Route path='/login' element={<Login />} />
        {authCtx.role === Role.Admin ? <Route exact path='/' element={<PrivateRoute />}>
          <Route exact path='/' element={<DashBoardCard />} />
        </Route>: <Route exact path='/' element={<PrivateRoute />}>
        <Route path='/' element={<Dashboard/>} />
        </Route>}
        {authCtx.role.includes(Role.Admin) && <Route exact path='/' element={<PrivateRoute />}>
          <Route path='/organization' element={<Organization />} />
        </Route>}

        {authCtx.role.includes(Role.Admin) && <Route exact path='/' element={<PrivateRoute />}>
          <Route path='/viewOrg/:id/:orgName' element={<ViewOrg />} />
        </Route>}

        {authCtx.role === Role.SuperAdmin && <Route exact path='/' element={<PrivateRoute/>}>
        <Route path='/vendor' element={<Vendor/>}/>
          </Route>}

          {authCtx.role === Role.Admin && <Route exact path='/' element={<PrivateRoute/>}>
        <Route path='/vendor' element={<VendorSearch/>}/>
          </Route>}
          {authCtx.role === Role.Admin && <Route exact path='/' element={<PrivateRoute/>}>  
            <Route path='/sales' element={<Sales/>}/>
          </Route>}
        <Route exact path='/' element={<PrivateRoute />}>
          <Route path='/eggLog' element={<EggLog />} />
        </Route>
        <Route exact path='/' element={<PrivateRoute />}>
          <Route path='/inventory' element={<Inventory />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
        <Route path='/server' element={<ServerError/>}/>
      </Routes>



    </>
  );
}

export default App;