import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from "react-select";
import { connectGet, connectPost } from "../../helpers/Connect";
import { validationSchema } from "../../helpers/form-validation/VendorForm";
import AuthContext from "../../store/auth-context";
import './customError.css';

const AddVendor = (props) => {

    const [show,setShow] = useState(false)
    const [vendorOptions, setVendorOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(props.editData.vendor_category ? {value: props.editData.vendor_category, label: props.editData.vendor_category}:[])
    
    const preloadedValues = {
        name: props.editData.name,
        email: props.editData.email,
        phone_number: props.editData.phone_number,
        vendor_address: props.editData.vendor_address

    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: preloadedValues
        
    });

    const authCtx = useContext(AuthContext)

    
    const getDropDownData = () => {
        let finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/entityList/2'
        connectGet(finalUrl, authCtx.token)
        .then(response => {
            let selectData = response.payload.map(item => ({value:item.name, label:item.name}))
            setVendorOptions(selectData)
        })
    }

    const handleOptionChange= (selectedOptions) => {
        console.log(selectedOptions)
        setSelectedOption(selectedOptions)
    }

    const onSubmit = formData => {
        let methodIdentifier
        let addVendorUrl
        if(Object.keys(props.editData).length === 0){
            methodIdentifier = 'POST'
            addVendorUrl = process.env.REACT_APP_HAWKEYE_URL + '/vendor'

        }else{
            methodIdentifier = 'PATCH'
            addVendorUrl = process.env.REACT_APP_HAWKEYE_URL + '/vendor/'+props.editData.id
        }
        let finalData = JSON.stringify({
            ...formData,
            vendor_category: selectedOption.value
        })
        connectPost(addVendorUrl, finalData, authCtx.token, methodIdentifier)
        .then(response => {
           props.cancelHandler()
        })
        .catch(error => {
            setShow(true)
        })
    }

    useEffect(() => {
        getDropDownData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Container>
                <br/>
                <h3>{Object.keys(props.editData).length === 0 ? 'Add':'Edit'} Vendor</h3>
                <hr/>
            </Container>
            <Container>
                <Card>
                    <Card.Body>
                    <Alert variant="danger" show={show}>Internal Error. Please try again later</Alert>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Vendor Name</Form.Label>
                                <Form.Control type="text"
                                    name="name"
                                    placeholder="Enter vendor name"
                                    {...register('name')}
                                 />
                                <span className="validation-error">{errors.vendor_name?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    {...register('email')}
                                 />
                                 <span className="validation-error">{errors.email?.message}</span>
                                
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number"
                                    name="phone_number"
                                    placeholder="Enter phone number"
                                    {...register('phone_number')}
                                 />
                                 <span className="validation-error">{errors.phone_number?.message}</span>
                                
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Vendor Category</Form.Label>
                                <Select placeholder="select category"
                                        options={vendorOptions}
                                        value={selectedOption}
                                        onChange={handleOptionChange} />
                            
                                <span className="validation-error">{errors.vendor_category?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Form.Label>Vendor Address</Form.Label>
                                <Form.Control type="text"
                                    name="vendor_address"
                                    placeholder="Enter vendor address"
                                    {...register('vendor_address')}
                                 />
                                <span className="validation-error">{errors.vendor_address?.message}</span>
                            </Form.Group>
                            <Button variant="custom" type="submit">
                                Submit
                            </Button> &nbsp;
                            <Button variant="customSecondary" type="button" onClick={() => props.cancelHandler()}>
                                Cancel
                            </Button>
                        </Form>

                    </Card.Body>
                </Card>
            </Container>
        </>
    )

}


export default AddVendor