import React, { useContext, useState } from 'react';
import {
  Button, Card, Container, Nav, Navbar,
  Offcanvas
} from 'react-bootstrap';
import { AiOutlineShop } from 'react-icons/ai';
import { BsEgg } from 'react-icons/bs';
import { MdOutlineDashboardCustomize, MdOutlineInventory, MdSell } from 'react-icons/md';
import { RiOrganizationChart } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Role from '../../helpers/Role';
import AuthContext from '../../store/auth-context';
import './Navigation.css';




function Navigation() {
  const [show, setShow] = useState(false);
  const authCtx = useContext(AuthContext)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    authCtx.logout()
  }


  return (
    <>
      <Navbar sticky="top" className='color-nav' variant="dark" expand={false}>
        <Container fluid>
          <Navbar.Brand href="#"><Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow}/> &nbsp; Poultry Pack Admin</Navbar.Brand>

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            show = {show}
            onHide={handleClose}
          >
            <Offcanvas.Header className='color-nav' closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='color-nav'>
              <Nav className="justify-content-end flex-grow-4 pe-3">
              <Link to='/' onClick={handleClose}><MdOutlineDashboardCustomize /> Dashboard</Link>
                <hr />
                {authCtx.role.includes(Role.Admin) && <><Link to='/organization' onClick={handleClose}><RiOrganizationChart /> Organizations</Link>
                <hr /> </>}
                {authCtx.role.includes(Role.Admin) && <><Link to='/vendor' onClick={handleClose}><AiOutlineShop /> Vendors</Link>
                <hr /> </>}
                {authCtx.role === Role.Admin && 
                <>
                <Link to='/sales' onClick={handleClose}><MdSell /> Sales</Link>
                <hr /> 
                </>}
               
                <Link to="/eggLog" onClick={handleClose}><BsEgg /> Egg Log</Link>
                <hr/>
                <Link to="/inventory" onClick={handleClose}><MdOutlineInventory /> Inventory</Link>
                <hr/>
                <p>Logged in as: {authCtx.firstName} {authCtx.lastName}</p>
                <Button variant='outline-light' onClick={handleLogout}>Logout</Button>

              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;