
export const hiden = {
    farm: ['id', 'farm_org_id', 'timestamp', 'status', 'zone_id'],
    building: ['id', 'farm_id', 'status'],
    batch: ['id', 'farm_id', 'batch_sheds', 'timestamp', 'status', 'createdby', 'batch_end'],
    user: ['id', 'org_id', 'timestamp', 'createdby', 'status'],
    eggLog: ['id', 'boxes', 'trays', 'loose_eggs', 'total_eggs', 'damage_eggs', 'livestock_mortality', 'feed_delivered', 'today_egg_rate'],
    vendor: ['id', 'timestamp', 'createdby'],
    sales: ['id']
}



