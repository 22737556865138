import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BrokenEgg from './egg.png';
import EggLens from './EggLens.png';

const ErrorPage = (props) => {
    return (
        <>
            <Container>
                <br /><br />
                <Row className="justify-content-md-center">
                    <Col xs={12} sm={4} md={4}>
                        <img src={props.code === 500 ? BrokenEgg : EggLens} style={{ maxWidth: '10rem', display: 'flex' }} alt="" />
                        <h4>{props.message}</h4>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ErrorPage;