import React, { useContext, useState, useEffect } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import { connectDelete, connectGet, connectPost } from "../helpers/Connect"
import { toast } from 'react-toastify';
import SearchableCard from "../components/Card/SearchableCards";
import AuthContext from "../store/auth-context";
import './tabs.css'
import LoaderPage from "../components/Loader/LoaderPage";



const VendorSearch = () => {
    const [cardData, setCardData] = useState([])
    const [clientData, setClientData] = useState([])
    const [loading, setLoading] = useState(false)
    const authCtx = useContext(AuthContext)
    const getAllVendors = () => {
        const vendorURL = process.env.REACT_APP_HAWKEYE_URL + '/vendor'
        connectGet(vendorURL, authCtx.token)
            .then(response => {
                setCardData(response.payload)

            }).catch(error => {
                console.log(error)
            })
    }

    const getClientVendors = () => {
        const vendorURL = process.env.REACT_APP_HAWKEYE_URL + '/vendor/orgVendor/' + authCtx.orgId
        connectGet(vendorURL, authCtx.token)
            .then(response => {
                setClientData(response.payload)
                setLoading(false)

            }).catch(error => {
                console.log(error)
            })
    }

    const buttonClickHandler = (id, btnName) => {
        if (btnName === 'Add') {
            const addVendorOrgUrl = process.env.REACT_APP_HAWKEYE_URL + '/vendor/orgVendor'
            const reqBody = JSON.stringify({
                "org_id": authCtx.orgId,
                "vendor_id": id
            })
            connectPost(addVendorOrgUrl, reqBody, authCtx.token)
            .then(response => {
                getAllVendors()
                getClientVendors()
                toast.success("Vendor Added Successfully", {
                    position: toast.POSITION.TOP_CENTER
                  });
            }).catch(error => {
                console.log(error)
            })

        }else{
            const deleteVendorOrgUrl = process.env.REACT_APP_HAWKEYE_URL + '/vendor/orgVendor/' + id
            connectDelete(deleteVendorOrgUrl, authCtx.token)
            .then(response => {
                getAllVendors()
                getClientVendors()
                toast.success("Vendor deleted Successfully", {
                    position: toast.POSITION.TOP_CENTER
                  });
            }).catch(error => {
                console.log(error)
            })
        }
    }
    useEffect(() => {
        //getLogData()
        setLoading(true)
        getAllVendors()
        getClientVendors()
       
      // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [])
    return (
        loading ? <LoaderPage/>:
        <>
        <Container>
            <br/>
            <h3>Vendors</h3>
            <hr/>
        </Container>
        <Container>
        <Tabs defaultActiveKey="addedVendors" className="mb-3" >
            <Tab tabClassName="myclass" eventKey="addedVendors" title="Your Vendors" >
            <SearchableCard cardData={clientData} 
                            buttonName="Remove"
                            buttonClickHandler={buttonClickHandler}/>
            </Tab>
            <Tab eventKey="available" title="Available Vendors">
            <SearchableCard cardData={cardData} 
                            buttonName="Add"
                            buttonClickHandler={buttonClickHandler}
                            />
            </Tab>
        </Tabs>

        </Container>
        
        
        </>
        
    )
}

export default VendorSearch