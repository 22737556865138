import React, {useState} from "react";
import jwtDecode from "jwt-decode";

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => {},
    logout: () => {},
});

export const AuthContextProvider = (props) => {
    const initialToken = localStorage.getItem('token')
    const [token, setToken] = useState(initialToken)
    const [role, setRole] = useState(initialToken != null ? jwtDecode(initialToken)['https://poutrypack.com/user_metadata'].role: '')
    const [orgId, setOrgId] = useState(initialToken != null ? jwtDecode(initialToken)['https://poutrypack.com/user_metadata'].org_id: '')
    const [firstName, setFirstName] = useState(initialToken != null ? jwtDecode(initialToken)['https://poutrypack.com/user_profile'].first_name: '')
    const [lastName, setlastname] = useState(initialToken != null ? jwtDecode(initialToken)['https://poutrypack.com/user_profile'].last_name: '')

    const userIsLoggedIn = !!token;

    const loginHandler = (token) => {
        setToken(token)
        var decoded = jwtDecode(token)
        setRole(decoded['https://poutrypack.com/user_metadata'].role)
        setOrgId(decoded['https://poutrypack.com/user_metadata'].org_id)
        setFirstName(decoded['https://poutrypack.com/user_profile'].first_name)
        setlastname(decoded['https://poutrypack.com/user_profile'].last_name)
        localStorage.setItem('token', token)
    };

    const logoutHandler = () => {
        setToken(null)
        localStorage.clear()
    };

    const contextValue = {
        token: 'Bearer ' + token,
        isLoggedIn: userIsLoggedIn,
        role: role,
        orgId: orgId,
        firstName: firstName,
        lastName: lastName,
        login: loginHandler,
        logout: logoutHandler
    }



    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext;