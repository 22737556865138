import React, { useContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import CustomDataTable from "../components/DataTable/CustomDataTable";
import AddVendor from "../components/Forms/AddVendor";
import LoaderPage from "../components/Loader/LoaderPage";
import CustomModal from "../components/Modal/CustomModal";
import { connectDelete, connectGet } from "../helpers/Connect";
import { hiden } from "../helpers/datatable-hidden-columns/hidden";
import AuthContext from "../store/auth-context";

function Vendor(){
    const [addVendor, setAddVendor] = useState(false)
    const [tableData, setTableData] = useState([])
    const [showDataTable, setShowDataTable] = useState(true)
    const [editData, setEditData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [rowId, setRowId] = useState('')
    const authCtx = useContext(AuthContext)

    const getVendorData = () => {
        const url = process.env.REACT_APP_HAWKEYE_URL + '/vendor'
        connectGet(url, authCtx.token)
        .then(response => {
            setTableData(response.payload)
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
        })
    }
    const addButtonClickHandler = (identifier) => {
        setEditData([])
        setAddVendor(true)
        setShowDataTable(false)
    }

    const cancelButtonClickHandler = () => {
        setShowDataTable(true)
        setAddVendor(false)
        getVendorData()
        setShowModal(false)
    }
    
    const editButtonClickHandler = (data, identifier) => {
        setEditData(data)
        setShowDataTable(false)
        setAddVendor(true)
        
    }

    const deleteRow = () => {
        const deleteUrl = process.env.REACT_APP_HAWKEYE_URL + '/vendor/' + rowId
        connectDelete(deleteUrl, authCtx.token)
        .then(response => {
            cancelButtonClickHandler()
        })
        .catch(error => {
            console.log(error)
        })

    }
    const handleModalClose = () => {
        setShowModal(false)
    }

    const deleteHandler = (rowId) => {
        setShowModal(true)
        setRowId(rowId)
    }

    useEffect(() => {
        //getLogData()
        setLoading(true)
        getVendorData()
       
      // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [])
    return (
        loading ? <LoaderPage/> :
        <>
        <Container>
            <br />
            <h3>Vendors</h3>
            <hr />
        </Container>
        <CustomModal show={showModal}
                message="Are you sure you want to delete?"
                handleModalClose={handleModalClose} handlerFunction={deleteRow} />
        <Container>
        {addVendor && <AddVendor cancelHandler={cancelButtonClickHandler} editData = {editData}/>}
        { showDataTable &&
          <CustomDataTable data={tableData}
          hiddenColumns={hiden.vendor}
          addButtonClickHandler = {addButtonClickHandler}
          editButtonClickHandler = {editButtonClickHandler}
          deleteHandler = {deleteHandler}
          identifier="Vendor"
          view={false}
          edit={true}
          /> 
        }
        
        </Container>
        

        </>
        
        

    )
}

export default Vendor;