import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from 'chart.js';
import React from "react";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  );

const ProductionLineChart = (props) => {

    const data = {
        labels: ['2022-05-12','2022-05-13','2022-05-14','2022-05-15','2022-05-16','2022-05-17','2022-05-18' ],
        datasets: [
          {
            label: 'Eggs',
            data: [7, 6, 9, 6, 4, 3, 2],
            fill: true,
            barThickness: 30,
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: "rgb(153, 102, 255)",
            borderWidth: 1,
            borderRadius: Number.MAX_VALUE,
            borderSkipped: false,
          },
        ]
      };
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            
            labels: {
                boxWidth: 10,
                usePointStyle: false,
                pointStyle: 'circle'
            }
          },
          title: {
            display: false,
            text: 'Revenue',
          },
        },
      };
    return (
        <>
        <Bar data={data} options={options}/>
        </>
    )
}

export default ProductionLineChart