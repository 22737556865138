import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import CustomDataTable from "../components/DataTable/CustomDataTable";
import AddBatch from "../components/Forms/AddBatch";
import AddFarm from "../components/Forms/AddFarm";
import AddShed from "../components/Forms/AddShed";
import AddUser from "../components/Forms/AddUser";
import CustomModal from "../components/Modal/CustomModal";
import PlainModal from "../components/Modal/PlainModel";
import Timeline from "../components/Timeline/Timeline";
import { toast } from 'react-toastify';
import { connectDelete, connectGet, connectPost } from "../helpers/Connect";
import { hiden } from '../helpers/datatable-hidden-columns/hidden';
import AuthContext from "../store/auth-context";
import LoaderPage from "../components/Loader/LoaderPage";

function ViewOrg() {
    const { id, orgName } = useParams()
    const [tableData, setTableData] = useState('')
    const [hiddenColumns, setHiddenColumns] = useState('')
    const [countData, setCountData] = useState({})
    const [optionData, setOptionData] = useState([])
    const [batchShedData, setBatchShedData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showPlainModal, setShowPlainModal] = useState(false)
    const [showBatchModal, setShowBatchModal] = useState(false)
    const [showSelect, setShowSelect] = useState(false)
    const [showTable, setShowTable] = useState(true)
    const [identity, setIdentity] = useState('')
    const [identitylevel, setIdentityLevel] = useState('')
    const [selectedOption, setSelectedOption] = useState(null)
    const [isActive, setIsActive] = useState('')
    const [addForm, setAddForm] = useState('')
    const [editForm, setEditForm] = useState({})
    const [rowId, setRowId] = useState('')
    const [batchEndId, setBatchEndId] = useState('')
    const [ loading, setLoading ] = useState(false)
    const authCtx = useContext(AuthContext)
    const { REACT_APP_HAWKEYE_URL } = process.env


    const populateOptions = (data) => {
        var optionData = data.map(item => ({ value: item.id, label: item.farm_name }))
        setOptionData(optionData)

    }

    const activeCardHandler = (identifier) => {
        setIsActive(identifier)
    }

    const cardClickHandler = (identifier, level) => {
        if (identifier !== 'farm' && identifier !== 'user') {
            activeCardHandler(identifier)
            setShowSelect(true)
            setShowTable(false)
            setSelectedOption(null)
            setIdentity(identifier)
            setIdentityLevel(level)
            setAddForm('')
        } else {
            activeCardHandler(identifier)
            setShowSelect(false)
            setShowTable(true)
            setIdentity(identifier)
            setIdentityLevel(level)
            setAddForm('')
            getData(identifier, id, level)

        }
    }

    function addButtonClickHandler(identifier) {
        setEditForm({})
        setShowTable(false)
        setAddForm(identifier)


    }
    const editButtonClickHandler = (data, identifier) => {
        setEditForm(data)
        setShowTable(false)
        setAddForm(identifier)
    }
    const viewButtonClickHandler = (row) => {
        getBatchShedHousing(row.id)
        setShowPlainModal(true)
    }
    const cancelFarmHandler = () => {
        setAddForm('')
        setShowTable(true)
        cardClickHandler('farm', 'org')
        getCountsData()
    }

    const cancelShedHandler = () => {
        setAddForm('')
        setShowSelect(true)
        getData('building', selectedOption.value, 'farm')
        setShowTable(true)
        getCountsData()
    }

    const cancelBatchHandler = () => {
        setAddForm('')
        setShowSelect(true)
        getData('batch', selectedOption.value, 'farm')
        setShowTable(true)
        getCountsData()
    }

    const cancelUserHandler = () => {
        setAddForm('')
        setShowTable(true)
        cardClickHandler('user', 'nothing')
        getCountsData()
    }

    const handleOptionChange = (selectedOptions) => {
        setSelectedOption(selectedOptions)
        getData(identity, selectedOptions.value, identitylevel)
        setAddForm('')
        setShowTable(true)
    }

    const getData = (identifier, pathId, level) => {
        let finalUrl
        if (identifier === 'user') {
            finalUrl = REACT_APP_HAWKEYE_URL + '/auth/users/' + pathId
        } else {
            finalUrl = REACT_APP_HAWKEYE_URL + '/' + identifier + '/' + pathId + '?level=' + level
        }
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                setHiddenColumns(hiden[identifier])
                setTableData(response.payload)
                if (identifier === 'farm') {
                    populateOptions(response.payload)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    const getBatchShedHousing= (id) =>{
        const finalUrl = REACT_APP_HAWKEYE_URL + '/batch/shedInfo/' + id
        connectGet(finalUrl, authCtx.token)
        .then(response => {
            setBatchShedData(response.payload)
        })
        .catch(error => {
            console.log(error)
        })
    }
    const getCountsData = () => {
        const finalUrl = REACT_APP_HAWKEYE_URL + '/organization/counts/' + id
        connectGet(finalUrl, authCtx.token)
            .then(response => {
                setCountData(response.payload[0])
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const deleteRow = () => {
        let finalUrl;

        if (identity === 'user') {
            finalUrl = REACT_APP_HAWKEYE_URL + '/auth/users/' + rowId
        } else {
            finalUrl = REACT_APP_HAWKEYE_URL + '/' + identity + '/' + rowId
        }

        connectDelete(finalUrl, authCtx.token)
            .then(response => {
                switch (identity) {
                    case 'farm':
                        cancelFarmHandler();
                        break;
                    case 'building':
                        cancelShedHandler();
                        break;
                    case 'batch':
                        cancelBatchHandler();
                        break;
                    case 'user':
                        cancelUserHandler();
                        break;
                    default:
                        console.log("hi");

                }
                toast.success('Deletion successful', {
                    position: toast.POSITION.TOP_CENTER
                  });
                setShowModal(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const deleteHandler = (rowId) => {
        setShowModal(true)
        setRowId(rowId)
        console.log(identity)
        console.log(rowId)
    }

    const endBatchHandlerModel = (rowId) => {

        setBatchEndId(rowId)
        setShowBatchModal(true)
        
    }

    const endBatchHandler = () => {
        const finalUrl = REACT_APP_HAWKEYE_URL + '/batch/endBatch/' + batchEndId
        var data = JSON.stringify({})
        connectPost(finalUrl, data, authCtx.token, 'PATCH')
        .then(response => {
            setShowBatchModal(false)
            toast.success(response.payload, {
                position: toast.POSITION.TOP_CENTER
              });
            cancelBatchHandler()
        }).catch(error => {
            console.log(error)
        })

    }

    const handleModalClose = () => {
        setShowModal(false)
    }
    const handleBatchModalClose = () => {
        setShowBatchModal(false)
    }

    const handlePainModalClose = () => {
        setShowPlainModal(false)
    }

    useEffect(() => {
        setLoading(true)
        getData('farm', id, 'org')
        getCountsData()
        setHiddenColumns(hiden['farm'])
        activeCardHandler('farm')
        setIdentity('farm')
        setIdentityLevel('org')
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        loading ? <LoaderPage/> :
        <>
            <Container>
                <br />
                <h3>Organization: {orgName}</h3>
                <hr />
            </Container>
            <CustomModal show={showModal}
                message="Are you sure you want to delete?"
                handleModalClose={handleModalClose} handlerFunction={deleteRow} />
            <Container>
                <Row>
                    <Col lg={true}>
                        <Card className={isActive === 'farm' ? "text-center card-custom" : "text-center"}
                            onClick={() => cardClickHandler('farm', 'org')}
                            style={{ cursor: "pointer" }}>
                            <Card.Header><b>Farms</b></Card.Header>
                            <Card.Body>
                                <Card.Title><h2>{countData.farm_count}</h2></Card.Title>
                            </Card.Body>
                        </Card></Col>
                    <Col lg={true}>
                        <Card className={isActive === 'building' ? "text-center card-custom" : "text-center"} onClick={() => cardClickHandler('building', 'farm')} style={{ cursor: "pointer" }}>
                            <Card.Header>Buildings</Card.Header>
                            <Card.Body>
                                <Card.Title><h2>{countData.building_count}</h2></Card.Title>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={true}>
                        <Card className={isActive === 'batch' ? "text-center card-custom" : "text-center"} onClick={() => cardClickHandler('batch', 'farm')} style={{ cursor: "pointer" }}>
                            <Card.Header>Batches</Card.Header>
                            <Card.Body>
                                <Card.Title><h2>{countData.batch_count}</h2></Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={true}>
                        <Card className={isActive === 'user' ? "text-center card-custom" : "text-center"} onClick={() => cardClickHandler('user', 'nothing')} style={{ cursor: "pointer" }}>
                            <Card.Header>Users</Card.Header>
                            <Card.Body>
                                <Card.Title><h2>{countData.user_count}</h2></Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
            {
                showSelect && <Container>
                    <br />
                    <Card >
                        <Card.Body>
                            <h4>Select Farm</h4>
                            <Select options={optionData}
                                value={selectedOption}
                                onChange={handleOptionChange} />
                        </Card.Body>

                    </Card>
                </Container>
            }

            {tableData !== '' && showTable && <Container>

                <CustomDataTable data={tableData}
                    hiddenColumns={hiddenColumns}
                    identifier={identity}
                    addButtonClickHandler={addButtonClickHandler}
                    editButtonClickHandler={editButtonClickHandler}
                    deleteHandler={deleteHandler}
                    viewButtonClickHandler={viewButtonClickHandler}
                    endBatchHandler = {endBatchHandlerModel}
                    view={identity === 'batch' ? true : false}
                    end={identity === 'batch' ? true : false}
                    edit={identity=== 'batch' ? false: true} />
            </Container>}
            <Container>
                {addForm === 'farm' && <AddFarm orgId={id} cancelHandler={cancelFarmHandler} editData={editForm} />}
                {addForm === 'building' && <AddShed farmId={selectedOption.value} editData={editForm} cancelHandler={cancelShedHandler} />}
                {addForm === 'user' && <AddUser orgId={id} editData={editForm} cancelHandler={cancelUserHandler} />}
                {addForm === 'batch' && <AddBatch farmId={selectedOption.value} editData={editForm} cancelHandler={cancelBatchHandler} />}
            </Container>

            <PlainModal show={showPlainModal} 
                        title="Batch Data" 
                        batchShedData = {batchShedData} 
                        passedComponent={<Timeline />} 
                        handleModalClose={handlePainModalClose} />
            
            <CustomModal show={showBatchModal}
                         message="Are you sure you want to end batch. Once confirmed it cannot be undone"
                         handleModalClose={handleBatchModalClose}
                         handlerFunction={endBatchHandler}
                         deleteButton="End Batch" />
            
        </>
    )
}

export default ViewOrg;