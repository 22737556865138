import { ArcElement, Chart as ChartJS } from "chart.js";
import DoughnutLabel from "chartjs-plugin-doughnutlabel-rebourne";
import React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";



ChartJS.register(
    ArcElement,
    DoughnutLabel
);



const LogView = (props) => {

    
    const data = {
        labels: [
            props.data.boxes + ' Boxes',
            props.data.trays + ' Trays',
            props.data.loose_eggs + ' Loose Eggs'
        ],
        datasets: [{
            label: 'My First Dataset',
            data: [props.data.boxes, props.data.trays, props.data.loose_eggs],
            backgroundColor: [
                '#2820A7',
                '#6C63FF',
                '#C730FD'
            ],
            hoverOffset: 4
        }],
       
    };

    const options = {
        cutout: '70%',
       
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    padding: 10,
                    pointStyle: 'circle',
                    usePointStyle: true
                }
             },

            title: {
                display: false,
                text: 'Total Eggs: ' + props.data.total_eggs,
                color: 'black',
                font: {
                    size: 28
                },
                padding: {
                    top: 30,
                    bottom: 30
                },

                responsive: true,
                animation: {
                    animateScale: true,
                }
            },
            doughnutlabel: {
                labels: [{
                  text: props.data.total_eggs,
                  font: {
                    size: 40,
                    weight: 'bold'
                  }
                }, {
                  text: 'Total'
                }]
              }
        }

    }
    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>General Info</Card.Header>
                        <Card.Body>
                            <ListGroup >
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Farm Name</Col>
                                        <Col>{props.data.farm_name}</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Batch Name</Col>
                                        <Col>{props.data.batch_name}</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Building Name</Col>
                                        <Col>{props.data.building_name}</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Log Date</Col>
                                        <Col>{props.data.log_date}</Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>

                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Header>Other Info</Card.Header>
                        <Card.Body >
                            <ListGroup >
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Damaged Eggs</Col>
                                        <Col>{props.data.damage_eggs}</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Mortality</Col>
                                        <Col>{props.data.livestock_mortality}</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Feed</Col>
                                        <Col>{props.data.feed_delivered}</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>NECC Egg Rate</Col>
                                        <Col>{props.data.today_egg_rate} &#8377;</Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card><br />


                </Col>
            </Row>


            <Card >
                <Card.Header>Egg Info</Card.Header>
                <Card.Body>
                    <center>
                        <div style={{ width: '25em' }}>
                            <Doughnut data={data} options={options} />
                        </div>
                    </center>


                </Card.Body>
            </Card><br />





        </>
    )
}


export default LogView