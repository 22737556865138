import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    organization_name: Yup.string().required('Organization name is required'),
    start_date: Yup.string()
    .required('Batch end date is required')
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Batch start date must be a valid date in the format YYYY-MM-DD'),
    end_date: Yup.string()
    .required('origin date is required')
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Origin date must be a valid date in the format YYYY-MM-DD')
    
  });