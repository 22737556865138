import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Card, Form, Button, Container, Col, Row } from "react-bootstrap";
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import Select from "react-select";
import { connectGet, connectPost } from "../../helpers/Connect";
import { validationSchema } from "../../helpers/form-validation/SaleForm";
import AuthContext from "../../store/auth-context";


const AddSale = (props) => {

    const [farmOptionData, setFarmOptionData] = useState([])
    const [vendorOptionData, setVendorOptionData] = useState([])
    const [selectedFarmOption, setSelectedFarmOption] = useState(null)
    const [selectedVendorOption, setSelectedVendorOption] = useState(null)
    
    const authCtx = useContext(AuthContext)

    const {
        register,
        control,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    
    const getDropDownData = (identifier) => {
        let finalUrl

        if(identifier === 'farm'){
            finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/' + identifier + '/' + authCtx.orgId + '?level=org'
        }else{
            finalUrl = process.env.REACT_APP_HAWKEYE_URL + '/vendor/orgVendor/' + authCtx.orgId
        }
        connectGet(finalUrl, authCtx.token)
        .then(response => {
            var selectData
            if(identifier === 'farm'){
                selectData = response.payload.map(item => ({ value: item.id, label: item.farm_name }))
                setFarmOptionData(selectData)
            }else{
                selectData = response.payload.map(item => ({ value: item.id, label: item.name }))
                setVendorOptionData(selectData)
            }  
        })
        .catch(error => {
            console.log(error)
        })
    }

    const handleOptionChange = (selectedOptions) => {
        setSelectedFarmOption(selectedOptions)
    }

    const handleVendorOptionChange = (selectedOptions) => {
        setSelectedVendorOption(selectedOptions)
    }

    const handleTotalEggs = (e) => {
       if (e.target.name === 'total_eggs'){
        var eggsTotalBySize = e.target.value
        var boxes = Math.floor(eggsTotalBySize/210)
        var trays = Math.floor((eggsTotalBySize %210)/30) 
        var eggs = Math.floor((eggsTotalBySize %210) % 30)
        setValue("boxes", boxes)
        setValue("trays", trays)
        setValue("loose_eggs", eggs)
       }else{
           let total_eggs
           let boxes
           let trays
           let loose_eggs
           if(e.target.name === 'boxes'){
             boxes = e.target.value * 210
             trays = getValues().trays != null ? getValues().trays * 30 : 0
             loose_eggs = getValues().loose_eggs != null ? getValues().loose_eggs: 0
           }else if(e.target.name === 'trays'){
            boxes = getValues().boxes != null ? getValues().boxes * 210 : 0
            trays = e.target.value * 30
            loose_eggs = getValues().loose_eggs != null ? getValues().loose_eggs: 0
           }else {
            boxes = getValues().boxes != null ? getValues().boxes * 210 : 0
            trays = getValues().trays != null ? getValues().trays * 30 : 0
            loose_eggs = e.target.value * 1
           }
           total_eggs = boxes + trays + loose_eggs
           setValue("total_eggs", total_eggs)
       }  
    }

    const onSubmit = formData => {
        formData['log_date'] = new Date(formData.log_date)
        const finalObj = {
            ...formData,
            farm_id: selectedFarmOption.value,
            vendor_id: selectedVendorOption.value
        }
        const addSaleUrl = process.env.REACT_APP_HAWKEYE_URL + '/saleSummary'
        connectPost(addSaleUrl, JSON.stringify(finalObj), authCtx.token)
        .then(response => {
            toast.success("Sale record added successfully", {
                position: toast.POSITION.TOP_CENTER
              });
              props.cancelButtonClickHandler()
        }).catch(error => {
            toast.error("Internal error encountered. Please try again!", {
                position: toast.POSITION.TOP_CENTER
              });
        })
    }

    useEffect(() => {
        
        getDropDownData("farm")
        getDropDownData("vendor")
       
      // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [])

    return (
        <>
            <Container>
                <br />
                <h3>Add Egg Sale</h3>
                <hr />
            </Container>
            <Container>
                <Card>
                    <Card.Body>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Select placeholder="select farm"
                                    options={farmOptionData}
                                    value={selectedFarmOption}
                                    onChange={handleOptionChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">

                                <Select placeholder="Select Vendor"
                                    options={vendorOptionData}
                                    value={selectedVendorOption}
                                    onChange={handleVendorOptionChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">

                                <Form.Control type="date"
                                    name="log_date"
                                    placeholder="select date"
                                    {...register('log_date')}
                                />
                                <span className="validation-error">{errors.logdate?.message}</span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicText">
                                <Controller
                                    control={control}
                                    name="total_eggs"
                                    render={({field: {onChange, ...field}}) => {
                                        return <Form.Control type="number"
                                        name="total_eggs"
                                        placeholder="Total Eggs"
                                        onChange={(v) => {
                                            onChange(v);
                                            handleTotalEggs(v);
                                          }}
                                        {...field}
                                    />
                                    }}
                                    />
                                
                                <span className="validation-error">{errors.total_eggs?.message}</span>
                            </Form.Group>
                            <Row className="align-items-center">
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">
                                    <Controller
                                    control={control}
                                    name="boxes"
                                    render={({field: {onChange, ...field}}) => {
                                        return <Form.Control type="number"
                                        name="boxes"
                                        placeholder="Boxes"
                                        onChange={(v) => {
                                            onChange(v);
                                            handleTotalEggs(v);
                                          }}
                                        {...field}
                                    />
                                    }}
                                    />
                                        <span className="validation-error">{errors.boxes?.message}</span>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">
                                    <Controller
                                    control={control}
                                    name="trays"
                                    render={({field: {onChange, ...field}}) => {
                                        return <Form.Control type="number"
                                        name="trays"
                                        placeholder="trays"
                                        max="6"
                                        onChange={(v) => {
                                            onChange(v);
                                            handleTotalEggs(v);
                                          }}
                                        {...field}
                                    />
                                    }}
                                    />
                                        <span className="validation-error">{errors.trays?.message}</span>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicText">

                                    <Controller
                                    control={control}
                                    name="loose_eggs"
                                    render={({field: {onChange, ...field}}) => {
                                        return <Form.Control type="number"
                                        name="loose_eggs"
                                        placeholder="loose eggs"
                                        max="29"
                                        onChange={(v) => {
                                            onChange(v);
                                            handleTotalEggs(v);
                                          }}
                                        {...field}
                                    />
                                    }}
                                    />
                                        <span className="validation-error">{errors.loose_eggs?.message}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="formBasicText">

                                <Form.Control type="number"
                                    name="sale_egg_rate"
                                    step="0.01"
                                    placeholder="Sale Egg Rate"
                                    {...register('sale_egg_rate')}
                                />
                                <span className="validation-error">{errors.sale_egg_rate?.message}</span>
                            </Form.Group>
                            <Button variant="custom" type="submit">
                                Submit
                            </Button> &nbsp;
                            <Button variant="customSecondary" onClick={() => props.cancelButtonClickHandler()}>
                                Cancel
                            </Button>

                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>

    )

}

export default AddSale;